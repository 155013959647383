import store from "@/store";
import EnumConstants from "../constants/EnumConstants";

export default {
    // ==============================================选择的组织=============================================//
    /**
     * 保存选择组织
     */
    saveSelectedOrgList(orgItem) {
        store.dispatch("temp/refreshSelectedOrgList", orgItem || []);
    },
    /**
     * 获取选择组织
     */
    getSelectedOrgList() {
        if (store.state && store.state.temp) {
            return store.state.temp.selectedOrgList || [];
        } else {
            return [];
        }
    },
    /**
     * 移除选择的组织
     */
    removeSelectedOrgList() {
        store.dispatch("temp/refreshSelectedOrgList", []);
    },

    // ==============================================源url=============================================//
    /**
     * 保存源url
     */
    saveSourceUrl(url) {
        store.dispatch("temp/refreshSourceURL", url);
    },
    /**
     * 获取源url
     */
    getSourceUrl() {
        if (store.state && store.state.temp) {
            return store.state.temp.sourceUrl || "";
        } else {
            return "";
        }
    },
    /**
     * 移除源url
     */
    removeSourceUrl() {
        store.dispatch("temp/refreshSourceURL", "");
    },

    // ==============================================公文-选择栏=============================================//
    /**
     * 保存公文-选择栏
     */
    saveDocsTabState(tab) {
        store.dispatch("temp/refreshDocsTabState", tab);
    },
    /**
     * 获取公文-选择栏
     */
    getDocsTabState() {
        if (store.state && store.state.temp) {
            return store.state.temp.docs.tabState || "0";
        } else {
            return "0";
        }
    },
    /**
     * 移除公文-选择栏
     */
    removeDocsTabState() {
        store.dispatch("temp/refreshDocsTabState", "");
    },

    // ==============================================计划模板=============================================//
    /**
     * 保存下发计划模板
     */
    saveSendPlanTemplate(data) {
        store.dispatch("temp/refreshSendPlanTemplate", data);
    },
    /**
     * 获取下发计划模板
     */
    getSendPlanTemplate() {
        if (store.state && store.state.temp) {
            return store.state.temp.sendPlanTemplateObj || null;
        } else {
            return null;
        }
    },
    /**
     * 移除下发计划模板
     */
    removeSendPlanTemplate() {
        store.dispatch("temp/removeSendPlanTemplate");
    },

    // ==============================================上传配置=============================================//
    /**
     * 保存上传配置
     */
    saveUploadConfig(data) {
        store.dispatch("temp/setUploadConfig", data || {});
    },
    /**
     * 获取上传配置
     */
    getUploadConfig() {
        if (store.state && store.state.temp) {
            return store.state.temp.uploadConfig || {};
        } else {
            return {};
        }
    },

    // ==============================================上传平台=============================================//
    /**
     * 保存上传配置
     */
    saveUploadPlatform(data) {
        store.dispatch("temp/setUploadPlatform", data || "");
    },
    /**
     * 获取上传配置
     */
    getUploadPlatform() {
        if (store.state && store.state.temp) {
            return store.state.temp.uploadPlatform || EnumConstants.UPLOAD_PLATFORM.HUAWEI;
        } else {
            return EnumConstants.UPLOAD_PLATFORM.HUAWEI;
        }
    },
    /**
     * 保存上传失效时间
     */
    saveUploadFileIOExpire(expire) {
        store.dispatch("temp/setUploadFileIOExpire", expire || 7);
    },
    /**
     * 获取上传失效时间
     */
    getUploadFileIOExpire() {
        if (store.state && store.state.temp) {
            return store.state.temp.uploadFileIOExpire || 7;
        } else {
            return 7;
        }
    },

    /**
     * 移除tempstore 数据
     */
    removeTempStore() {
        store.dispatch("temp/removeTempStore");
    },

    /**
     * 移除数据可视化store
     */
    removeDataVisualStore() {
        store.dispatch("temp/removeDataVisual");
    },

    // ==============================================是否是必须处理事项进入=============================================//
    /**
     * 保存是否是必须处理事项进入
     */
    saveISMustHandle(data) {
        store.dispatch("temp/setISMustHandle", data);
    },
    /**
     * 获取是否是必须处理事项进入
     */
    getISMustHandle() {
        if (store.state && store.state.temp) {
            return store.state.temp.isMustHandle || false;
        } else {
            return false;
        }
    },
};
