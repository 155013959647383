export default {
    /**
     * 地区 limitCount = 0 不限制个数
     */
    formatterAreaNameListStr(item, limitCount = 0, noData = "无") {
        if (!item.extJsonObj) {
            return noData;
        }
        const areaList = item.extJsonObj.areaList;

        if (!areaList || areaList.length == 0) {
            return "通用地区";
        }
        const tempList = this.formatterAreaNameList(item, limitCount);
        const count = areaList.length;
        const isSliceList = count > limitCount && limitCount != 0;

        const resultStr = tempList.join("、");
        return isSliceList ? `${resultStr}...` : resultStr;
    },
    /**
     * 地区 limitCount = 0 不限制个数
     */
    formatterAreaNameListString(item, limitCount = 0, noData = "无") {
        if (!item.extJsonObj) {
            return noData;
        }
        const areaList = item.extJsonObj.areaList;

        if (!areaList || areaList.length == 0) {
            return noData;
        }
        const tempList = this.formatterAreaNameList(item, limitCount);
        const count = areaList.length;
        const isSliceList = count > limitCount && limitCount != 0;

        const resultStr = tempList.join("、");
        return isSliceList ? `${resultStr}...` : resultStr;
    },
    /**
     * 地区列表
     */
    formatterAreaNameList(item, limitCount = 0) {
        if (!item.extJsonObj) {
            return [];
        }
        const areaList = item.extJsonObj.areaList;

        if (!areaList || areaList.length == 0) {
            return [];
        }
        const count = areaList.length;
        let sourceList = [];
        const isSliceList = count > limitCount && limitCount != 0;
        if (isSliceList) {
            sourceList = areaList.slice(0, limitCount);
        } else {
            sourceList = areaList;
        }
        const templateList = [];
        sourceList.map((object) => {
            const str = this.formatterFullNameAddress(object);
            templateList.push(str);
        });
        return templateList;
    },
    /**
     * 单个全地址拼接
     */
    formatterFullNameAddress(item) {
        if (!item) return "-";

        if (item instanceof String) {
            return item;
        }
        let str = "";
        if (item.province && item.province.length > 0) {
            str = item.province;
        }
        if (item.city && item.city.length > 0) {
            str = `${str}-${item.city}`;
        }
        if (item.district && item.district.length > 0) {
            str = `${str}-${item.district}`;
        }
        return str;
    },
    /**
     * 格式字符串处理
     */
    formatterCellValueStr(col, row, value) {
        if (typeof value === "number" && value) {
            return value;
        }
        if (value && value.toString().length > 0) {
            return value;
        } else {
            return "-";
        }
    },
    /**
     * 格式字符串处理
     */
    formatterDetailValueStr(value) {
        if (typeof value === "number" && value) {
            return value;
        }
        if (value && value.length > 0) {
            return value;
        } else {
            return "-";
        }
    },
    /**
     * 格式字符串处理
     */
    formatterNumberStr(value) {
        if (value == null || value == undefined || value.length <= 0) {
            return "-";
        }
        return value;
    },
};

/**
 * 教育
 */
export const formatterEducation = (education) => {
    switch (education) {
        case 1:
            return "小学";
        case 2:
            return "初中";
        case 3:
            return "高中";
        case 4:
            return "中专";
        case 5:
            return "大专";
        case 6:
            return "本科";
        case 7:
            return "研究生";
        case 8:
            return "博士";
        case 9:
            return "未知";
        default: {
            return "-";
            break;
        }
    }
};

/**
 * 车牌号处理
 * */
export const formatterDeviceAllNumberStr = (item, val = "-") => {
    const deviceNumber = item.deviceNumber;
    const deviceLaterNumber = item.deviceLaterNumber;

    if (item == null || (!deviceNumber && !deviceLaterNumber)) {
        return val;
    }
    let string = deviceNumber;
    if (deviceLaterNumber && deviceLaterNumber.length > 0) {
        if (string) {
            string = deviceNumber + "," + deviceLaterNumber;
        } else {
            string = deviceLaterNumber;
        }
    }
    return string;
};
