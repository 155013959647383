/**
 *  临时数据 常用的业务数据
 */
const state = {
    dataVisualPattern: false, // 看板模式 是否自动刷新或者切换
    dataVisualRefresh: {
        industryTime: 60, // 切换行业 yxl 20241223 默认60分钟
        realTime: 5, // 实时刷新
    }, // 看板实时刷新间隔时间或者自动切换行业间隔时间

    selectedOrgList: [], // 选择组织
    sourceUrl: "", // 源url
    // 公文模块
    docs: {
        tabState: "0", // 选中栏
    },
    studentClearData: [], // 学员弹窗删除的学员
    isStudentClearAll: false, // 学员弹窗是否删除全部学员
    documentPreview: {}, // 公文预览数据
    sendPlanTemplateObj: null, // 计划模板
    uploadConfig: {}, // 上传的配置
    uploadPlatform: null, // 上传平台
    uploadFileIOExpire: 7, // 上传导入导出文件过期时间
    isMustHandle: false, // 必须处理事项
};
const mutations = {
    /**
     * 看板模式
     */
    // eslint-disable-next-line no-shadow
    SET_DATA_VISUAL_PATTERN: (state, dataVisualPattern) => {
        state.dataVisualPattern = dataVisualPattern;
    },
    /**
     * 看板定时时间(实时刷新间隔时间或者自动切换行业间隔时间)
     */
    // eslint-disable-next-line no-shadow
    SET_DATA_VISUAL_REFRESH: (state, dataVisualRefresh) => {
        state.dataVisualRefresh = dataVisualRefresh;
    },

    /**
     * 保存选择的组织
     */
    // eslint-disable-next-line no-shadow
    SET_SELECTED_ORG_LIST: (state, orgList) => {
        state.selectedOrgList = orgList;
    },

    /**
     * 保存源url
     */
    // eslint-disable-next-line no-shadow
    SET_SOURCE_URL: (state, url) => {
        state.sourceUrl = url;
    },
    /**
     * 学员弹窗右侧是否进行了清除
     * @param {*} state
     * @param {*} data
     */
    SET_STUDENT_CLEAR_DATA: (state, data) => {
        state.studentClearData = data;
    },
    SET_IS_STUDENT_CLEAR_ALL: (state, data) => {
        state.isStudentClearAll = data;
    },

    /**
     * 保存公文选择栏
     */
    // eslint-disable-next-line no-shadow
    SET_DOCS_TAB_STATE: (state, tabState) => {
        state.docs.tabState = tabState;
    },
    SET_DOCUMENT_PREVIEW: (state, data) => {
        state.documentPreview = data;
    },

    /**
     *  保存计划模板
     */
    // eslint-disable-next-line no-shadow
    SET_SEND_PLAN_TEMPLATE: (state, data) => {
        state.sendPlanTemplateObj = data;
    },

    /**
     * 保存上传配置
     */
    SET_UPLOAD_CONFIG: (state, data) => {
        state.uploadConfig = data || {};
    },
    /**
     * 保存上传平台
     */
    SET_UPLOAD_PLATFORM: (state, data) => {
        state.uploadPlatform = data || null;
    },
    /**
     * 保存上传导入导出文件过期时间
     */
    SET_UPLOAD_FILE_IO_EXPIRE: (state, data) => {
        state.uploadFileIOExpire = data || null;
    },
    /**
     * 必须处理事项
     */
    SET_IS_MUST_HANDLE: (state, data) => {
        state.isMustHandle = data || false;
    },
};

const actions = {
    setDataVisualPattern: ({ commit }, dataVisualPattern) => {
        commit("SET_DATA_VISUAL_PATTERN", dataVisualPattern);
    },
    setDataVisualRefresh: ({ commit }, dataVisualRefresh) => {
        commit("SET_DATA_VISUAL_REFRESH", dataVisualRefresh);
    },
    /**
     * 保存组织
     */
    refreshSelectedOrgList({ commit }, org) {
        commit("SET_SELECTED_ORG_LIST", org);
    },
    /**
     * 学员弹窗右侧是否进行了清除
     * @param {*} data
     */
    setStudentClearData({ commit }, data) {
        const temp = {};
        const result = data.reduce((pre, curr) => {
            // temp[`${curr.userId}${curr.orgId}`] ? '' : temp[`${curr.userId}${curr.orgId}`] = true && pre.push(curr);
            temp[`${curr.id}`] ? "" : (temp[`${curr.id}`] = true && pre.push(curr));
            return pre;
        }, []);
        commit("SET_STUDENT_CLEAR_DATA", result);
    },
    setIsStudentClearAll({ commit }, data) {
        commit("SET_IS_STUDENT_CLEAR_ALL", data);
    },
    /**
     *  保存源url
     */
    refreshSourceURL({ commit }, url) {
        commit("SET_SOURCE_URL", url);
    },
    /**
     *  保存公文选择栏
     */
    refreshDocsTabState({ commit }, tab) {
        commit("SET_DOCS_TAB_STATE", tab);
    },
    // 保存公文预览数据
    saveDocumentPreview: ({ commit }, data) => {
        commit("SET_DOCUMENT_PREVIEW", data);
    },

    /**
     * 刷新计划模板
     */
    refreshSendPlanTemplate({ commit }, data) {
        commit("SET_SEND_PLAN_TEMPLATE", data);
    },
    /**
     * 移除计划模板
     */
    removeSendPlanTemplate({ commit }) {
        commit("SET_SEND_PLAN_TEMPLATE", null);
    },

    /**
     * 保存上传的endPoint
     */

    setUploadConfig({ commit }, data) {
        commit("SET_UPLOAD_CONFIG", data);
    },
    /**
     * 保存上传的平台
     */
    setUploadPlatform({ commit }, data) {
        commit("SET_UPLOAD_PLATFORM", data);
    },
    /**
     * 保存上传的文件失效时间
     */
    setUploadFileIOExpire({ commit }, data) {
        commit("SET_UPLOAD_FILE_IO_EXPIRE", data);
    },
    /**
     * 保存是否必须处理事项
     */
    setISMustHandle({ commit }, data) {
        commit("SET_IS_MUST_HANDLE", data);
    },

    /**
     * 移除temp数据
     */
    removeTempStore({ commit }) {
        commit("SET_DATA_VISUAL_REFRESH", {
            industryTime: 60, // 切换行业 yxl 20241223 默认60分钟
            realTime: 5, // 实时刷新
        });
        commit("SET_DATA_VISUAL_PATTERN", false);
        commit("SET_SELECTED_ORG_LIST", []);
        commit("SET_SOURCE_URL", "");
        commit("SET_DOCS_TAB_STATE", "0");
    },
    /**
     * 移除看板数据
     */
    removeDataVisual({ commit }) {
        commit("SET_DATA_VISUAL_REFRESH", {
            industryTime: 60, // 切换行业 yxl 20241223 默认60分钟
            realTime: 5, // 实时刷新
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
