// htmlToPdf.js
// 导出页面为PDF格式
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
import printJS from "print-js";
import "print-js/dist/print.css";
import { HudGlobal } from "./HudGlobal";

export default {
    install(Vue, options) {
        // 导出pdf
        Vue.prototype.getPdf = async function (dom, title, marginLeft = 5, marginTop = 5) {
            HudGlobal.showLoadingFullScreen("正在导出...");
            html2Canvas(document.querySelector(dom), {
                allowTaint: true,
                taintTest: false,
                useCORS: true,
                y: 0, // 对Y轴进行裁切
                // width:1200,
                // height:5000,
                dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
                scale: 4, //按比例增加分辨率
            })
                .then(function (canvas) {
                    const imgData = canvas.toDataURL("image/png", 1.0);
                    const imgWidth = 210 - marginLeft * 2; // A4 宽度减去两边边距 (mm)
                    const pageHeight = 297 - marginTop * 2; // A4 高度减去上下边距 (mm)
                    const imgHeight = (canvas.height * imgWidth) / canvas.width - marginTop;
                    let heightLeft = imgHeight;
                    const doc = new JsPDF("p", "mm", "a4"); // 创建 A4 大小的 PDF 文档
                    let position = 0;
                    // 添加第一页
                    doc.addImage(imgData, "JPEG", marginLeft, marginTop, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                    // 计算剩余高度，处理多页情况
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, "JPEG", marginLeft, position + marginTop, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }
                    HudGlobal.dismissLoadingFullScreen();
                    // 保存 PDF 文件
                    doc.save(title + ".pdf");
                })
                .catch((e) => {
                    console.log("====导出pdf失败=====", e);
                    HudGlobal.dismissLoadingFullScreen();
                    HudGlobal.showWarningWithMessage("导出pdf失败");
                });
        };
        // 打印
        Vue.prototype.pintDom = function (dom, title) {
            // 转图片打印
            HudGlobal.showLoadingFullScreen("正在打印...");
            html2Canvas(document.querySelector(dom), {
                allowTaint: false,
                useCORS: false,
                height: document.querySelector(dom).outerHeight,
                width: document.querySelector(dom).outerWidth,
                windowWidth: document.body.scrollWidth,
                windowHeight: document.body.scrollHeight,
            }).then((canvas) => {
                const url = canvas.toDataURL();
                printJS({
                    printable: url,
                    type: "image",
                    documentTitle: title,
                });
                HudGlobal.dismissLoadingFullScreen();
            });
        };
    },
};
