import http from "@utils/http";
import TBUserManger from "../../utils/user/TBUserManger";

/**
 * 通用接口控制类 2020-11-19 lzb
 */
export default {
    /**
     * 查询当前登录者的授权范围下的地区
     */
    getCommonAreaList(params) {
        return http.get("/common/area/list", params);
    },

    /**
     * 查询当前登录者的授权范围下的行业 - 列表
     */
    getCommonIndustryList(params) {
        return http.get("/common/industry/list", params);
    },
    /**
     * 查询当前登录者的授权范围下的行业 - 树形
     */
    getCommonIndustryTree(params) {
        return http.get("/common/industry/tree", params);
    },
    /**
     * 查询当前登录者的授权范围下的行业 - 下拉列表
     */
    getCommonIndustryBox(params, isApi = false) {
        if (isApi) {
            return http.get("/api/common/industry/box", params, { isEncrypt: true });
        } else {
            return http.get("/common/industry/box", params);
        }
    },
    /**
     * 查询当前登录者的授权范围下的类别 - 列表
     */
    getCommonSubjectList(params) {
        return http.get("/common/subject/list", params);
    },
    /**
     * 查询当前登录者的授权范围下的类别 - 树形
     */
    getCommonSubjectTree(params) {
        return http.get("/common/subject/tree", params);
    },
    /**
     * 查询当前登录者的授权范围下的类别 - 下拉列表
     */
    getCommonSubjectBox(params) {
        return http.get("/common/subject/box", params);
    },
    /**
     * 查询当前登录者的授权范围下的岗位 - 列表
     */
    getCommonDutyList(params) {
        return http.get("/common/duty/list", params);
    },
    /**
     * 查询当前登录者的授权范围下的岗位 - 树形
     */
    getCommonDutyTree(params) {
        return http.get("/common/duty/tree", params);
    },
    /**
     * 查询当前登录者的授权范围下的岗位 - 下拉列表
     */
    getCommonDutyBox(params) {
        return http.get("/common/duty/box", params);
    },
    /**
     * @description: H5页面下载地址
     * @param {*} params
     * @return {*}
     */
    getStudentLast(params) {
        return http.get("/api/student/appupgrade/last/statistics", params, { isEncrypt: true });
    },
    /**
     * 查询当前登录者的授权范围下的组织
     */
    getCommonOrgPage(params) {
        return http.get("/common/org/page", params);
    },
    /**
     * 查询当前登录者的授权范围下的组织
     */
    getCommonOrgList(params) {
        return http.get("/common/org/list", params);
    },

    /**
     * 查询当前登录者管辖范围下的人员
     */
    getCommonOrgUserPage(params) {
        return http.get("/common/org/user/page", params);
    },

    /**
     * 按身份或部门分类查询组织下的人员
     */
    getCommonUserClassify(params) {
        return http.get("/common/user/classify", params);
    },
    /**
     * 查询当前登录者的授权范围下的组织个数,人员个数,以及所有的人员
     */
    getCommonUserList(params) {
        return http.get("/common/user/list", params);
    },

    /**
     * 查询培训监督员（用于勾选）
     * @param params
     */
    getCommonTrainAdminList(params) {
        return http.get("/system/train/admin/list", params);
    },
    /**
     * 查询企业是否支持公开报名
     * @param params
     */
    getRegisterPublic(params) {
        return http.get("/system/subject/org/allow/register/public", params);
    },
    /**
     * 获取组织类型lzb V1.0.15 2021-10-12
     */
    getOrgTypeList(params) {
        return http.get("/org/manage/typecode/param", params);
    },
    /**
     *
     * @param {识别营业执照} params
     * @returns
     */
    getOcrBusinessLicense(params, isApi = false) {
        if (isApi) {
            return http.get("/api/ocr/businessLicense", params);
        }
        return http.get("/ocr/businessLicense", params);
    },
    getOcrImg(params, typeCode) {
        console.log("getOcrImg", TBUserManger.isLogin());
        if (typeCode == "businessLicense") {
            return this.getOcrBusinessLicense(params);
        } else if (typeCode == "roadTransportLicense") {
            return this.getOcrRoadTransportLicense(params);
        }
    },
    /**
     *
     * @param {识别道路运输经营许可证} params
     * @returns
     */
    getOcrRoadTransportLicense(params, isApi = false) {
        if (isApi) {
            return http.get("/api/ocr/roadTransportLicense", params, { isEncrypt: true });
        }
        return http.get("/ocr/roadTransportLicense", params);
    },
    /**
     * yxl 20241107 T-Z0939-企业包年 获取所有页面付费模式是否弹窗数据
     */
    getCheckPaymentModelInfo(params) {
        return http.get("/org/bill/expired/msg", params);
    },
};
