import loginhttp from "../http/loginhttp.js";
import { Constants } from "../constants/Constants";
import StoreMainManger from "@utils/db/StoreMainManger";
import TBUserManger from "@utils/user/TBUserManger";
import StoreUserDB from "@utils/db/StoreUserDB";
import StoreGlobalDB from "@utils/db/StoreGlobalDB";
import DeviceHelper from "@utils/helper/DeviceHelper";
import { refreshMenuRoutes } from "@utils/router/RouteUtil";
import LoginManager from "@utils/manager/LoginManager";
import TagViewManager from "@views/layout/utils/TagViewManager";
import StoreTagViewDB from "@utils/db/StoreTagViewDB";
import EnumConstants from "../constants/EnumConstants.js";
import { loadUploadOSSType } from "../project-config/GlobalConfig.js";
import SessionStorageDBManager from "../db/SessionStorageDBManager.js";
import { removeAllTableColumns } from "../db/TableColumnsDB.js";
import StoreTempDB from "../db/StoreTempDB.js";

/**
 *  用户管理类
 */
// 登录
export default {
    // 当前的登陆身份
    currentLoginIdentity: "",

    /**
     * 现场或其它现场或其它情况临时登录, 向后台请求一个临时登录的ID,
     * 需要一个登录的用户ID情况临时登录, 向后台请求一个临时登录的ID, 需要一个登录的用户ID
     */
    preLogin(param) {
        return new Promise((resolve, reject) => {
            // 预登陆
            loginhttp
                .preLogin(param)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /**
     * 模拟登录
     */
    runAs(param) {
        const that = this;
        return new Promise((resolve, reject) => {
            param = DeviceHelper.getBrowserInfo(param);
            // 模拟登陆
            loginhttp
                .runAs(param)
                .then((res) => {
                    if (res.success) {
                        that.handleLoginSuccessEvent(res, (res2) => {
                            resolve(res2);
                        });
                    } else {
                        resolve(res);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * 登录
     */
    login(param) {
        const that = this;
        return new Promise((resolve, reject) => {
            param = DeviceHelper.getBrowserInfo(param);
            const loginParam = {
                productCode: EnumConstants.projectClackType.SYSTEM_CLAZZ_TB_ATB, // lzb 2022-09-27 新增参数
            };
            Object.assign(loginParam, param);
            that.currentLoginIdentity = param.userLoginIdentity;
            // 登陆
            loginhttp
                .login(loginParam)
                .then((res) => {
                    if (res.success) {
                        that.handleLoginSuccessEvent(res, (res2) => {
                            resolve(res2);
                        });
                    } else {
                        resolve(res);
                    }
                })
                .catch((error) => {
                    console.log("-------登录错误----", error);
                    reject(error);
                });
        });
    },

    /**
     *  登录成功处理事务 lzb 2021-06-22  框架替换
     */
    handleLoginSuccessEvent(res, cb) {
        const userData = res.data;
        // 移除数据 lzb2020 -07-18
        this.cleanAllLocationData();
        window.localStorage.removeItem("tokenExpiredTip");

        // 1. 学员登陆时不设置
        if (
            (this.currentLoginIdentity && this.currentLoginIdentity === Constants.organizeIndentityType.INDENTITY_STUDENT) ||
            (userData.userLoginIdentity && userData.userLoginIdentity === Constants.organizeIndentityType.INDENTITY_STUDENT)
        ) {
            this.currentLoginIdentity = "";

            // 保存token 修改密码使用
            if (userData.token && userData.token.length > 0) {
                StoreUserDB.saveUserToken(userData.token);
            }
            cb && cb(res);
            return false;
        } else if (!userData.permissionList || userData.permissionList.length == 0 || !userData.menuDtoList || userData.menuDtoList.length == 0) {
            // 当权限或菜单为空时不能登陆
            const newResult = {};
            newResult.code = 8;
            newResult.success = false;
            newResult.fail = true;
            newResult.data = null;
            newResult.description = "您没有权限登录!";
            cb && cb(newResult);
            return false;
        } else {
            // 保存菜单
            StoreMainManger.saveUserMenuData(userData.menuDtoList);

            // 保存用户信息
            const oldUserId = TBUserManger.getCurrentUserId();
            StoreUserDB.saveUserInfo(userData);

            // rqmei 20240218不同的用户移除全局信息 lzb 2020-06-30 V1.0.4
            if (!!oldUserId && oldUserId !== userData.userId) {
                StoreMainManger.removeContainerOfGlobalInfo();
            }

            // 保存token
            if (userData.token && userData.token.length > 0) {
                StoreUserDB.saveUserToken(userData.token);
            }
            // rqm 20240219 保存用户ID 用于判断强制刷新
            SessionStorageDBManager.setUserId(userData.userId);

            // lzb 20241008 保存用户ID
            StoreMainManger.saveUserIdToDB(userData.userId);
            // 获取上传的配置 lzb 20230321
            loadUploadOSSType();
            // // 登陆完成后加载菜单
            if (res.success && res.data) {
                // 修改密码
                if (res.data.passWordLength == 1 && res.data.defaultPasswordMod == 1) {
                    cb && cb(res);
                    return false;
                }
                // 刷新路由
                refreshMenuRoutes();
            }

            cb && cb(res);
        }
    },

    /**
     *  退出登录
     */
    logout() {
        const that = this;
        return new Promise((resolve, reject) => {
            let userLogout = {
                userId: TBUserManger.getCurrentUserId(),
                productCode: EnumConstants.projectClackType.SYSTEM_CLAZZ_TB_ATB, // lzb 2022-09-27 新增参数
            };
            userLogout = DeviceHelper.getBrowserInfo(userLogout);
            loginhttp
                .logout(userLogout)
                .then((res) => {
                    that.handleLogOutSuccessEvent();
                    resolve(res);
                })
                .catch((error) => {
                    // 退出失败
                    reject(error);
                });
        });
    },

    /**
     * 模拟退出登陆
     */
    runRelease() {
        const that = this;
        return new Promise((resolve, reject) => {
            const userData = TBUserManger.getCurrentUserInfo();
            let userLogout = {};
            userLogout.userName = userData.userName;
            // lzb 20230801 新增参数
            userLogout.userId = TBUserManger.getCurrentUserId();
            userLogout.runAs = userData.runAs;
            userLogout.runAsUserName = userData.runAsUserName;
            userLogout.runAsRealName = userData.runAsRealName;
            userLogout = DeviceHelper.getBrowserInfo(userLogout);
            loginhttp
                .runRelease(userLogout)
                .then((res) => {
                    that.handleReleaseLogOutSuccessEvent(resolve);
                    // resolve && resolve(res);
                })
                .catch((error) => {
                    // 退出失败
                    reject && reject(error);
                    that.gotoLoginPage();
                });
        });
    },

    /**
     * 退出登录成功处理事件 lzb 2021-06-22 框架替换
     */
    handleLogOutSuccessEvent() {
        // 退出成功
        this.gotoLoginPage();
        const that = this;
        setTimeout(() => {
            that.cleanAllLocationData();
        }, 1000);
    },

    /**
     * 退出登录
     */
    gotoLoginPage() {
        // lzb 20231220 退出登录
        let fromUrl = localStorage.getItem("fromUrl");
        if (fromUrl && fromUrl.length > 0) {
            if (!fromUrl.startsWith("http") && !fromUrl.startsWith("https")) {
                fromUrl = `${window.location.protocol}//${fromUrl}`;
            }
            window.location.replace(fromUrl);
            localStorage.removeItem("fromUrl");
        } else {
            LoginManager.checkJumpToLogin();
        }
    },

    /**
     * 登出模拟登录成功处理事务 lzb 2021-06-22 框架替换
     */
    handleReleaseLogOutSuccessEvent(resolve) {
        // lzb - 2020-07-30 新增
        StoreUserDB.removeUserToken();

        const userData = TBUserManger.getCurrentUserInfo();
        const loginParam = {};
        loginParam.userName = userData.runAsUserName;
        loginParam.runAs = userData.runAs;
        // 退出模拟登陆之后以管理员的身份登陆
        loginParam.userLoginIdentity = Constants.organizeIndentityType.INDENTITY_ADMIN;
        this.login(loginParam).then((loginData) => {
            if (resolve) {
                resolve(loginData);
            }
        });
    },

    /**
     * 切换登录 V1.0.9 lzb 2021-05-26
     */
    changeLogin(param) {
        return new Promise((resolve, reject) => {
            const that = this;
            loginhttp.changeLogin(param).then((res) => {
                if (res.success) {
                    that.removeDataFromChangeOrg();
                    that.handleLoginSuccessEvent(res, (res2) => {
                        resolve(res2);
                    });
                } else {
                    resolve(res);
                }
            });
        });
    },

    // 清除所有本地数据
    cleanAllLocationData() {
        // 移除菜单
        StoreMainManger.removeUserMenuListData();

        // 移除全局信息
        StoreGlobalDB.removeWaitAuditOrgCount();
        StoreGlobalDB.removeGuidedMineOrgFlag();
        StoreGlobalDB.removeUnreadNewsTotal();
        StoreGlobalDB.removeUnreadMessageTotal();

        // 移除用户数据
        StoreUserDB.removeAllUserData();

        // 移除tag
        StoreTagViewDB.removeAllTagView();

        // 移除临时数据 20241227 lzb
        StoreTempDB.removeDataVisualStore();

        // 移除标签 2020-07-22 lzb
        TagViewManager.removeAllTab();
        // 移除sessionStorage中的数据 - 20240219 rqm
        SessionStorageDBManager.removeAllSessionStorageData();

        // 20241111 rqm 登录成功清除已设置的动态table项
        removeAllTableColumns();
    },

    /**
     *  切换组织移除数据
     */
    removeDataFromChangeOrg() {
        // 移除菜单
        StoreMainManger.removeUserMenuListData();

        // 移除全局信息
        StoreGlobalDB.removeGlobalAllData();

        // 移除用户信息
        StoreUserDB.removeUserInfo();

        // 移除标签 2020-07-22 lzb
        TagViewManager.removeAllTab();
    },
};
