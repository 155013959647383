import BrowserDown from "./components/vue-browser-down/down";
import ScrollTop from "./components/scroll-top/ScrollTop.vue";
import WindowListener from "./utils/observers/WindowListener.js";
import { isSupportWebp } from "./utils/helper/SupportHelper.js";
import IPAddress from "./plugs/IPAddress";
import StoreMainManger from "./utils/db/StoreMainManger";

export default {
    name: "App",
    components: {
        BrowserDown,
        ScrollTop,
    },
    // 暴露reload方法，方便后面的组件调用
    provide() {
        return {
            reload: this.reload,
            keepAliveArr: [""],
        };
    },
    data() {
        return {
            isRouterAlive: true,
            loadingOptions: {
                showRouteLoading: true,
                baseURL: process.env.BASE_URL,
                loadingText: "努力加载页面中....",
            },
        };
    },
    mounted() {
        // lzb 监听浏览器关闭
        WindowListener.addMonitorBrowserCloseEvent();
        window.isSupportWebp = isSupportWebp();
        console.log("-------挂载---app--", process.env, window.isSupportWebp);

        IPAddress.getLocalIP((result) => {
            StoreMainManger.saveLocalIPInfoData(result);
        });
        
    },
    beforeDestroy() {
        WindowListener.removeBrowserCloseEvent();
        // 删除登陆模板的数据
        window.localStorage.removeItem("templateData");
    },
    methods: {
        // 重新加载
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
    },
};
