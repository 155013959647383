import Vue from "vue";
import { Loading } from "element-ui";

/**
 *   功能: 全局弹框
 *
 *   使用方式:
 *   1. 导入文件import {HudGlobal} from "@/utils/HudGlobal.js";
 *   2. 调用方法  HudGlobal.showAlertMessage('测试');
 *   如果要使用点击确定之后回调 HudGlobal.showAlertMessage('测试').then(function)
 *
 *
 * */
const currentVue = null;
let messageInstance = null;
let notifyInstance = null;
let showDialog = 0; // rqmei 20240219 是否以显示弹框
const HudGlobal = {
    /*
     *  显示 -确定 -弹框
     * */
    showAlertMessage(message, showClose = true) {
        const msg = message.length > 0 ? message : "您确定要做此操作吗!";
        this.getVue().$alert(msg, "温馨提示", {
            confirmButtonText: "确定",
            showClose,
        });
    },
    /**
     * 显示-操作-confirm-确定弹框
     * @param message   弹框内容
     * @param cancleMsg 消失弹框信息
     *
     */
    showAlertConfirmMessage(message, cancleMsg, confirmButtonText) {
        return this.showAlertConfirmMessages(message, cancleMsg, "温馨提示", confirmButtonText);
    },
    /**
     * 显示-操作-confirm-确定弹框
     * @param message   弹框内容
     * @param cancleMsg 消失弹框信息
     *
     */
    showAlertTwoButtonMessage(message, confirmButtonText) {
        return this.showAlertConfirmMessages(message, "", "温馨提示", confirmButtonText);
    },
    /**
     * 显示-操作-confirm-确定弹框
     * @param message   弹框内容
     * @param cancleMsg 消失弹框信息
     *
     */
    showAlertConfirmMessages(message, cancleMsg, title = "温馨提示", confirmButtonText) {
        const that = this;
        const msg = message.length > 0 ? message : "您确定要做这个操作吗?";

        if (cancleMsg == null || cancleMsg == undefined) {
            cancleMsg = "取消成功";
        } else {
            cancleMsg = cancleMsg.length > 0 ? cancleMsg : "取消成功";
        }

        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            that.getVue()
                .$confirm(msg, title, {
                    confirmButtonText: confirmButtonText || "确定",
                    dangerouslyUseHTMLString: true,
                    cancelButtonText: "取消",
                    type: "warning",
                    customClass: "tb-universal-hud-alert",
                })
                .then(() => {
                    // 确定之后执行方法
                    if (resolve) {
                        resolve();
                    }
                })
                .catch(() => {
                    // 取消之后
                    // that.showInfoWithMessage(cancleMsg);
                });
        });
    },

    /**
     * 显示-操作-confirm-确定弹框
     * @param message   弹框内容
     * @param cancleMsg 消失弹框信息
     *
     */
    showAlertTwoOperationMessages(message, showClose = true) {
        const that = this;
        const msg = message.length > 0 ? message : "您确定要做这个操作吗?";
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            that.getVue()
                .$confirm(msg, "温馨提示", {
                    confirmButtonText: "确定",
                    dangerouslyUseHTMLString: true,
                    cancelButtonText: "取消",
                    showClose,
                    type: "warning",
                })
                .then(() => {
                    // 确定之后执行方法
                    if (resolve) {
                        resolve();
                    }
                })
                .catch(() => {
                    reject && reject();
                });
        });
    },

    /*
     *  显示基础弹框信息
     * */
    showAlertMessages(message, ensureTitle) {
        const that = this;
        const msg = message.length > 0 ? message : "您确定要做这个操作吗?";

        const cancleMsg = "取消成功";
        const title = "温馨提示";

        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            that.getVue()
                .$confirm(msg, title, {
                    confirmButtonText: ensureTitle || "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    // 确定之后执行方法
                    if (resolve) {
                        resolve(true);
                    }
                })
                .catch(() => {
                    that.showInfoWithMessage(cancleMsg);
                    if (resolve) {
                        resolve(false);
                    }
                });
        });
    },
    /*
     *  显示基础弹框信息
     * */
    showAlert(opts = {}) {
        const that = this;
        const msg = opts.message;
        const title = opts.title || "温馨提示";
        const confirmButtonText = opts.confirmButtonText || "确定";
        const cancelButtonText = opts.cancelButtonText || "取消";

        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            that.getVue()
                .$confirm(msg, title, {
                    ...opts,
                    confirmButtonText,
                    cancelButtonText,
                    type: "warning",
                })
                .then(() => {
                    resolve && resolve();
                })
                .catch((action) => {
                    reject && reject(action);
                });
        });
    },
    /**
     * 显示单个按钮弹框
     */
    showEnsureAlertMessage(message, ensureTitle, type = "warning", showClose = true) {
        const that = this;
        const msg = message.length > 0 ? message : "您确定要做这个操作吗?";

        const title = "温馨提示";

        const configType = type != null && type.length > 0 ? type : "warning";
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            that.getVue()
                .$confirm(msg, title, {
                    confirmButtonText: ensureTitle || "我知道了",
                    showCancelButton: false,
                    type: configType,
                    showClose,
                })
                .then(() => {
                    // 确定之后执行方法
                    if (resolve) {
                        resolve(true);
                    }
                })
                .catch(() => {
                    if (resolve) {
                        resolve(false);
                    }
                });
        });
    },
    /**
     * 显示单个按钮弹框
     */
    showOneButtonAlertMessage(opts) {
        const that = this;
        const msg = opts.message || "您确定要做这个操作吗?";

        const title = opts.title || "温馨提示";

        const configType = opts.type || "warning";
        const confirmButtonText = opts.confirmButtonText || "确定";
        const showClose = opts.showClose || false;
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            that.getVue()
                .$confirm(msg, title, {
                    confirmButtonText,
                    showCancelButton: false,
                    type: configType,
                    showClose,
                })
                .then(() => {
                    resolve && resolve();
                })
                .catch(() => {
                    reject && reject();
                });
        });
    },
    /**
     * 显示单个按钮弹框
     */
    showOneButtonAlertMessageOnly(opts) {
        if (this.showDialog == 1) {
            return null;
        }
        const that = this;
        const msg = opts.message || "您确定要做这个操作吗?";

        const title = opts.title || "温馨提示";

        const configType = opts.type || "warning";
        const confirmButtonText = opts.confirmButtonText || "确定";
        const showClose = opts.showClose || false;
        that.showDialog = 1;
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            that.getVue()
                .$confirm(msg, title, {
                    confirmButtonText,
                    showCancelButton: false,
                    type: configType,
                    showClose,
                })
                .then(() => {
                    that.showDialog = 0;
                    resolve && resolve();
                })
                .catch(() => {
                    that.showDialog = 0;
                    reject && reject();
                });
        });
    },
    /*
     *   显示操作成功-hud
     * */
    showSuccessWithMessage(message) {
        if (message == null || message == undefined) {
            message = "操作成功";
        }
        const msg = message.length > 0 ? message : "操作成功!";
        messageInstance && messageInstance.close();
        messageInstance = this.getVue().$message({
            type: "success",
            message: msg,
        });
    },
    /*
     *  显示操作信息
     * */
    showInfoWithMessage(message) {
        if (message == null || message == undefined) {
            message = "取消成功";
        }
        const msg = message.length > 0 ? message : "取消成功";
        messageInstance && messageInstance.close();
        messageInstance = this.getVue().$message({
            type: "info",
            message: msg,
        });
    },
    /*
     *   显示操作失败-hud
     * */
    showErrorWithMessage(message) {
        if (message == null || message == undefined) {
            message = "操作失败!";
        }
        const msg = message.length > 0 ? message : "操作失败!";
        notifyInstance && notifyInstance.close();
        notifyInstance = this.getVue().$notify.error({
            title: "失败",
            message: msg,
        });
    },
    /*
     *   显示信息
     * */
    showMessage(message) {
        if (message == null || message == undefined) {
            message = "操作失败";
        }
        const msg = message.length > 0 ? message : "执行操作";
        messageInstance && messageInstance.close();
        messageInstance = this.getVue().$message({
            type: "info",
            message: msg,
        });
    },
    /*
     *
     *  显示警告信息
     * */
    showWarningWithMessage(message, duration = 3000) {
        if (message == null || message == undefined) {
            message = "操作失败";
        }
        const msg = message.length > 0 ? message : "操作失败";
        messageInstance && messageInstance.close();
        messageInstance = this.getVue().$message({
            type: "warning",
            message: msg,
            duration: duration,
        });
    },

    /**
     * 加载数据
     * @param message
     */
    showLoadingFullScreen(message) {
        // const msg = message == null || message.length == 0 ? "努力加载中" : message;
        // const options = {
        //     fullscreen: true,
        //     text: msg,
        //     lock: true,
        //     spinner: "el-icon-loading",
        //     background: "rgba(0, 0, 0, 0.7)"
        // };
        // Loading.service(options);
        Vue.prototype.$fullLoading.showLoading({ loadingText: message });
    },

    /**
     * 加载动画关闭
     */
    dismissLoadingFullScreen() {
        // const option = {};
        // // 单例
        // Loading.service(option).close();
        Vue.prototype.$fullLoading.dismissLoading();
    },
    /**
     * 获取当前vue
     * @returns {Vue | Vue}
     */
    getVue() {
        if (this.currentVue == null) {
            this.currentVue = new Vue();
        }
        return this.currentVue;
    },
};
export { HudGlobal };
