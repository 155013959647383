export default {
    /**
     * 时间周期
     */
    SUPERVISION_CONDITION_PERIOD: {
        YEAR: "SUPERVISION_CONDITION_PERIOD_YEAR", // 年
        PERIOD: "SUPERVISION_CONDITION_PERIOD", // 季度
        MONTH: "SUPERVISION_CONDITION_PERIOD_MONTH", // 月
        DAY: "SUPERVISION_CONDITION_PERIOD_DAY", // 日
        CUSTOM: "SUPERVISION_CONDITION_PERIOD_CUSTOM", // 自定义
    },
    /**
     * 检查分类
     */
    CHECK_CATEGORY: {
        TARGET: "CHECK_TARGET", // 检查对象
        TRAINING: "CHECK_TRAINING", // 检查培训要求
        TRAINING_VALUE: "CHECK_TRAINING_VALUE", // 检查培训要求值
    },

    /**
     * 检查对象类型
     */
    CHECK_TARGET_TYPE: {
        USER: "CHECK_TARGET_USER", // 企业人员
        DEVICE: "CHECK_TARGET_DEVICE", // 车辆
    },
    /**
     * 检查培训要求
     */
    CHECK_TRAINING_TYPE: {
        HOURS: "CHECK_TRAINING_HOURS", // 培训学时
        COURSE: "CHECK_TRAINING_COURSE", // 培训课件
        PLAN: "CHECK_TRAINING_PLAN_COMPLETE", // 完成所有计划
        HOURS_COUNT: "CHECK_TRAINING_HOURS_COUNT", // 学时个数
    },

    /**
     * 项目类型
     */
    projectClackType: {
        // 安途帮
        SYSTEM_CLAZZ_ATB: "prod_antubang",
        // 替比安途帮
        SYSTEM_CLAZZ_TB_ATB: "prod_tbantubang",
        // 驾培学习
        SYSTEM_CLAZZ_TB_JPXT: "prod_jiapeixuetang",
        // 风险预警平台
        SYSTEM_CLAZZ_RISK_WARNING: "prod_safety_risk",
        // 营销系统
        SYSTEM_CLAZZ_MARKETING: "prod_marketing",
    },
    // 企业入驻查询
    QUERY_ORG_INFO: {
        SETTLED: "SETTLED", // 企业入驻
        CHECKOUT: "CHECKOUT", // 校验是否可入驻
    },

    /**
     * app跳转类型 lzb 2021-07-23 蜀交安培
     */
    APP_LINK_TYPE: {
        IN_SIDE: "inside", // 内部跳转
        OUT_SIDE: "outside", // 外链
    },
    /**
     * 监督检查周期编码
     */
    PERIOD_CODE: {
        SUPERVISION_CONDITION_PERIOD_YEAR: "SUPERVISION_CONDITION_PERIOD_YEAR", // 年
        SUPERVISION_CONDITION_PERIOD: "SUPERVISION_CONDITION_PERIOD", // 季
        SUPERVISION_CONDITION_PERIOD_MONTH: "SUPERVISION_CONDITION_PERIOD_MONTH", // 月
        SUPERVISION_CONDITION_PERIOD_DAY: "SUPERVISION_CONDITION_PERIOD_DAY", // 日
        SUPERVISION_CONDITION_PERIOD_CUSTOM: "SUPERVISION_CONDITION_PERIOD_CUSTOM", // 自定义周期
    },
    /**
     * 报告类型
     */
    PLAN_CLAZZ: {
        COMMON: "common", // 普通计划
        INTENSIVE: "intensive", // 强化培训
    },

    /**
     * 培训类别类型
     */
    SUBJECT_TYPE: {
        COMMON: "common", // 普通培训类别
        INTENSIVE: "intensive", // 强化培训类别
        SAFE: "safe", // 安全培训类别
    },
    /** 数据来源类型（违法违规：illegalViolation，违法事故：illegalAccident，车辆违法记分管理：deviceIllegal） */
    INTENSIVE_DRIVER_BEHAVIOR_DATA_TYPE: {
        ILLEGAL_VIOLATION: "illegalViolation", // 违法违规
        ILLEGAL_ACCIDENT: "illegalAccident", // 违法事故
        DEVICE_ILLEGAL: "deviceIllegal", // 车辆违法记分管理
        DRIVER_LICENSE_ABNORMAL: "driverLicenseAbnormal", // 驾驶证异常
    },
    /**
     * 违法行为分类code
     * 基础数据编辑/新增的时候使用
     */
    CONVERSION_DATA_TYPE_CODE: {
        ILLEGAL: "3", // 违法违规
        ACCIDENT: "4", // 违法事故
        ILLEGAL_SCORE: "5", // 违法计分
        DRIVING_LICENCE: "6", // 驾驶证异常
        CAR_ANNUAL_REVIEW: "7", // 车辆年审异常
        DEVICE_OFFLINE_ABNORMAL: "8", // 设备离线异常
        DEVICE_INSTALL_ABNORMAL: "9", // 设备安装异常
        TRAINING: "10", // 安全培训异常
        INFO_MISMATCH: "11", // 信息不匹配
        YEAR_PLAN_NOT_FORMULATED: "12", // 年度安全培训计划未制定
        ORG_NAME: "2", // 组织名称
    },
    /**
     * 违法分类
     */
    ILLEGAL_CLAZZ_CODE: {
        CLAZZ_SAFETY_TRAINING: "CLAZZ_SAFETY_TRAINING", // 安全培训
        CLAZZ_ORG_SAFETY_TRAINING_YEAR_PLAN_NOT_FORMULATED: "CLAZZ_ORG_SAFETY_TRAINING_YEAR_PLAN_NOT_FORMULATED", // 企业安全培训年度计划未制定
        CLAZZ_ILLEGAL_VIOLATION: "CLAZZ_ILLEGAL_VIOLATION", // 违法违规
        CLAZZ_ILLEGAL_ACCIDENT: "CLAZZ_ILLEGAL_ACCIDENT", // 违法事故
        CLAZZ_ILLEGAL_RECEIVE: "CLAZZ_ILLEGAL_RECEIVE", // 违法记分
        CLAZZ_DRIVER_LICENSE_ABNORMAL: "CLAZZ_DRIVER_LICENSE_ABNORMAL", // 驾驶证异常
        CLAZZ_DEVICE_INSTALL_ABNORMAL: "CLAZZ_DEVICE_INSTALL_ABNORMAL", // 设备安装异常
        CLAZZ_DEVICE_OFFLINE_ABNORMAL: "CLAZZ_DEVICE_OFFLINE_ABNORMAL", // 设备离线异常
        CLAZZ_DEVICE_AUDIT_ABNORMAL: "CLAZZ_DEVICE_AUDIT_ABNORMAL", // 车辆审核异常
        CLAZZ_INFO_MISMATCH: "CLAZZ_INFO_MISMATCH", // 信息不匹配
        CLAZZ_OTHER: "CLAZZ_OTHER", // 其它
    },
    /**
     *     用户分类
     */
    USER_CLAZZ: {
        NO_TRAINEES: 100, // 非培训学员
        STAFF: 101, // 员工(管理员/普通学员)
        TRAINEES: 102, // 培训学员（挂考）
        TRAINEES_SUPERVISORS: 103, // 培训监督员（挂考）
    },
    /**
     * 证书动态配置参数
     */
    CERT_PARAM_CODE: {
        LY_CERT_BG_IMG: "ly_certBgImg", // 证书背景图
        LY_CERT_ICON: "ly_certIcon", // 证书图标 - 证书徽标
        LY_CERT_TITLE: "ly_certTitle", // 证书标题
        LY_BELONG_ORG_SEAL: "ly_belongOrgSeal", // 所属企业图章
        LY_BELONG_GOR_NAME: "ly_belongOrgName", // 所属企业名称
        LY_CHECK_ORG_SEAL: "ly_checkOrgSeal", // 考核机构（章
        LY_CHECK_ORG_NAME: "ly_checkOrgName", // 考核机构（章
        LY_TRAIN_ORG_NAME: "ly_trainOrgName", // 培训机构名称
        LY_TRAIN_ORG_SEAL: "ly_trainOrgSeal", // 培训机构图章
        LY_USE_DESCRIPTION: "ly_useDescription", // 使用说明
    },
    /**
     * 上传的平台
     */
    UPLOAD_PLATFORM: {
        ALI: "ALI",
        HUAWEI: "HUAWEI",
        TB: "TB",
        TIBI: "TIBI",
    },
    /**
     * 公用页面的权限前缀
     */
    PREFIX_PERMISSION: {
        SAFE_REGULAR: "safe_regular-", // 安全例会
    },
};

/**
 *  培训类型
 */
export const PRINT_TYPES = {
    SAFE_TRAIN_REPORT: "prod_tbantubang_safe_train_report", // 安途帮先锋版 安全培训档案 打印
    SAFE_TRAIN_REPORT_PLAN_LIST: "prod_tbantubang_safe_train_report_plan", // 安途帮先锋版 安全培训档案 计划 打印
    SPECIAL_TRAIN_REPORT: "prod_tbantubang_special_train_report", // 安途帮先锋版 专项培训档案 打印
    SPECIAL_TRAIN_REPORT_PLAN_LIST: "prod_tbantubang_special_train_report_plan", // 安途帮先锋版 专项培训档案 计划 打印
    SAFE_MEETING_REPORT: "prod_tbantubang_safe_meeting_report", // 安途帮先锋版 安全例会档案 打印
    SAFE_MEETING_REPORT_PLAN_LIST: "prod_tbantubang_safe_meeting_report_plan", // 安途帮先锋版  安全例会档案 计划 打印
    PRE_WORK_TRAIN_REPORT: "prod_tbantubang_pre_train_report", // 安途帮先锋版 岗前培训档案 打印
    PRE_WORK_TRAIN_REPORT_PLAN_LIST: "prod_tbantubang_pre_train_report_plan", // 安途帮先锋版  岗前培训档案 计划 打印
    TARGET_TRAIN_REPORT: "prod_tbantubang_intensive_train_report", // 安途帮先锋版 靶向培训档案 打印
    TARGET_TRAIN_REPORT_PLAN_LIST: "prod_tbantubang_intensive_train_report_plan", // 安途帮先锋版  靶向培训档案 计划 打印
    TWO_person_TRAIN_REPORT: "prod_tbantubang_two_person_train_report", // 安途帮先锋版 两类培训档案 打印
};

/**
 * 一日三检检查项类型 20240325
 */
export const DEVICE_CHECK_ITEM_TYPE = {
    CAR_OUT_BEFORE: "C001", // 车辆出车前
};

/**
 *  学历
 * 1小学、2初中、3高中、4中专、5大学专科、6大学本科、7硕士研究生、8博士研究生，9未知
 */
export const EDUCATION_MAP = {
    1: "小学",
    2: "初中",
    3: "高中",
    4: "中专",
    5: "大学专科",
    6: "大学本科",
    7: "硕士研究生",
    8: "博士研究生",
    9: "未知",
};
/**
 * 禁止名单的方式
 */
export const PROHIBIT_OPERATION_TYPE = {
    HAND_JOIN: "hand_join", // 手动加入
    AUTO_JOIN: "auto_join", // 自动加入
    HAND_REMOVE: "hand_remove", // 手动移除
    AUTO_REMOVE: "auto_remove", // 自动移除
};

export const provincesNameList = [
    "北京市",
    "天津市",
    "河北省",
    "山西省",
    "内蒙古自治区",
    "辽宁省",
    "吉林省",
    "黑龙江省",
    "上海市",
    "江苏省",
    "浙江省",
    "安徽省",
    "福建省",
    "江西省",
    "山东省",
    "河南省",
    "湖北省",
    "湖南省",
    "广东省",
    "广西壮族自治区",
    "海南省",
    "重庆市",
    "四川省",
    "贵州省",
    "云南省",
    "西藏自治区",
    "陕西省",
    "甘肃省",
    "青海省",
    "宁夏回族自治区",
    "新疆维吾尔自治区",
    "香港特别行政区",
    "澳门特别行政区",
    "台湾省",
];
/**
 * 使用模式
 */
export const billingModeList = [
    { code: 11, name: "试用阶段" },
    { code: 12, name: "正式使用(常规)" },
    { code: 13, name: " 正式使用(周期)" },
];
