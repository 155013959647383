import GlobalHelper from "@utils/helper/GlobalHelper";
import { formatterBillingModelValue, formatterPayChannelsValue } from "../../../finance/util/financeBillingInfoUtils";

export default {
    /**
     * 格式化组织状态
     */
    formatterOrgStateStr(item) {
        // 是否是对象
        let state = item;
        if (GlobalHelper.isObject(item)) {
            state = item.state;
        }
        // 状态（0：过期，1:草稿，2：停用，3:启用）,
        if (state == 0) {
            return "停用";
        } else if (state == 1) {
            return "正常";
        } else if (state == 2) {
            return "待审核";
        } else if (state == 3) {
            return "审核驳回";
        } else {
            return this.formatterNoDefaultStr();
        }
    },
    /**
     * 使用模式
     */
    formatterBillingMode(item) {
        // 是否是对象
        let billingMode = item;
        if (GlobalHelper.isObject(item)) {
            billingMode = item.billingMode;
        }
        return formatterBillingModelValue(billingMode);
    },

    /**
     * 付费模式通道
     */
    formatterPaymentChannels(item) {
        if (!item) {
            return "-";
        }
       
       return formatterPayChannelsValue(item.paymentChannels);
       
    },
    /*
     * 地址
     * */
    formatterAddressStr(item) {
        const name = item.address;
        let address = name;
        if (address == null || address.length == 0) {
            if (item.province != null && item.province.length != 0) {
                address += item.province;
            }
            if (item.city != null && item.city.length != 0) {
                address += item.city;
            }
            if (item.district != null && item.district.length != 0) {
                address += item.district;
            }
            if (item.name != null && item.name.length != 0) {
                address += item.name;
            }
        }
        if (address == null || address.length == 0) {
            return "-";
        }
        return address;
    },

    /**
     *  显示默认字符
     */
    formatterNoDefaultStr() {
        return "-";
    },
};
