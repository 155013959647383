import DateUtil from "../../../utils/DateUtil";
import FuncHelper from "../../../utils/helper/FuncHelper";

/**
 * 付费模式
 * @param {*} billingMode
 * @returns
 */
export const formatterBillingModelValue = (billingMode) => {
    const billingModeObj = {
        11: "试用阶段",
        12: "正式使用(常规)",
        13: "正式使用(周期)",
        null: "-",
    };
    if (billingMode) {
        return billingModeObj[billingMode];
    }
    return "-";
};
/**
 *
 * @returns 周期
 */
export const periodList = [
    { code: 1, name: "包年" },
    { code: 2, name: "包月" },
    { code: 3, name: "非固定周期" },
];
/**
 * 周期数据回显
 * @param {*} period
 * @returns
 */
export const formatterPeriodValue = (period) => {
    const periodObj = periodList.find((item) => item.code == period);
    if (periodObj) {
        return periodObj.name;
    }
    return "-";
};
/**
 * 处理付费通道
 */
export const formatterPayChannelsValue = (paymentChannels) => {
    if (!paymentChannels || paymentChannels.length <= 0) {
        return "-";
    }
    // 支付渠道 10：网络付费，20账户余额，30：信用额度 40 :学习券
    const paymentChannelsList = paymentChannels.split(",");
    const temp = [];
    let str = "";
    paymentChannelsList.map((code) => {
        if (code == 10) {
            str = "网络付费";
        } else if (code == 20) {
            str = "账户余额";
        } else if (code == 30) {
            str = "信用额度";
        } else if (code == 40) {
            str = "学习券";
        }
        temp.push(str);
    });

    const resultStr = temp.join("、");
    return resultStr;
};
/**
 * 价格统一处理
 * @param {*} val
 * @returns
 */
export const fenToMoneyBillingFixed = (val) => {
    if (val == null || val == undefined) {
        return "-";
    }
    return FuncHelper.regFenToYuan(val);
};
/**
 * 时间只展示年月日
 * @param {*} val
 * @returns
 */
export const formatterDateYMDValue = (val) => {
    if (val == null || val == undefined || val.length <= 0) {
        return "-";
    }
    return DateUtil.formatterDateYMDStr(val);
};
