import store from "@/store";
import { defaultBillingInfoListTableColumns } from "../constants/TableColumnsDefault";
/**
 *
 * @param {组织计费信息} billingInfoListNew
 */
export const setBillingInfoListTableColumns = (billingInfoListNew) => {
    store.dispatch("tableColumns/setBillingInfoList", billingInfoListNew);
};
/**
 *
 * @returns  组织计费信息
 */
export const getBillingInfoListTableColumns = () => {
    if (store.state && store.state.tableColumns) {
        const billingInfoList = store.state.tableColumns.billingInfoList;
        return billingInfoList || [];
    } else {
        return [];
    }
};
/**
 * 初始默认的动态列
 */
export const setDefaultBillingInfoListTableColumns = () => {
    setBillingInfoListTableColumns(defaultBillingInfoListTableColumns);
};
/**
 * 清除已保留的动态列表项的值
 */
export const removeAllTableColumns = () => {
    setDefaultBillingInfoListTableColumns();
    store.dispatch("tableColumns/removePageListObj");
};

/**
 * 保存动态表单
 * @param {*} key
 * @param {*} List
 */
export const setPageListDynamicListObjectByKey = (key, list) => {
    if (!key || key.length == 0) {
        console.log("设置的setPageListDynamicListObjectByKey的key不能是null");
        return false;
    }
    store.dispatch("tableColumns/setPageListObj", { key: key, value: list });
};

/**
 * 保存动态表单
 * @param {*} key
 * @param {*} List
 */
export const getPageDynamicListByKey = (key) => {
    if (!key || key.length == 0) {
        console.log("设置的getPageDynamicListByKey的key不能是null");
        return false;
    }
    if (store.state && store.state.tableColumns.pageListObj) {
        const list = store.state.tableColumns.pageListObj[key];
        return list || [];
    } else {
        return [];
    }
};

/**
 * 本地是否存在key
 */
export const isExistPageDynamicObjHasKey = (key) => {
    if (!key || key.length == 0) {
        console.log("设置的isExistPageDynamicObjHasKey的key不能是null");
        return false;
    }
    if (store.state && store.state.tableColumns.pageListObj) {
        return store.state.tableColumns.pageListObj.hasOwnProperty(key);
    }
    return false;
};
