import CryptoJS from "crypto-js";
import { isArray, isBase64Str, isEmptyObject, isNull, isUndefined } from "../helper/TypeChecker";

const encodeReserveRE = /[!'()*]/g;
const encodeReserveReplacer = (c) => `%${c.charCodeAt(0).toString(16)}`;
const commaRE = /%2C/g;
const encode = (str) => encodeURIComponent(str).replace(encodeReserveRE, encodeReserveReplacer).replace(commaRE, ",");

const decode = decodeURIComponent;
const TB_ROUTER_KEY = "ThisIsASecretKey";
const routeConf = {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: CryptoJS.enc.Utf8.parse("1234567812345678"),
};
/**
 * 加密
 */
export const stringifyQuery = (obj) => {
    if (!obj) return "";
    if (isEmptyObject(obj)) return "";
    if (isBase64Str(obj)) return obj;
    // console.log("====加密source===", obj);
    const result = Object.keys(obj)
        .map((key) => {
            const value = obj[key];
            if (isUndefined(value)) return "";
            if (isNull(value)) return encode(key);
            if (isArray(value)) {
                const resArray = [];
                value.forEach((item) => {
                    if (isUndefined(item)) return;
                    if (isNull(item)) {
                        resArray.push(encode(key));
                    } else {
                        resArray.push(`${encode(key)}=${encode(item)}`);
                    }
                });
                return resArray.join("&");
            }
            return `${encode(key)}=${encode(value)}`;
        })
        .filter((x) => x.length > 0)
        .join("&");
    const encryptResult = encryptWithAES(result, TB_ROUTER_KEY, routeConf);
    const routeParamRes = result ? `?${encryptResult}` : "";
    // console.log("===========stringifyQuery加密======result=%s======routeParamRes=%s", result, encryptResult);
    return routeParamRes;
};

/**
 * 解密
 */
export const parseQuery = (query) => {
    const res = {};
    // query = query.trim().replace(/^(\?|#|&)/, "");
    // if (!query || query.length == 0) {
    //     return res;
    // }
    if (!query || query.length == 0) {
        return res;
    }
    // console.log("====parseQuery前===", query);
    try {
        const queryParam = decryptWithAES(query, TB_ROUTER_KEY, routeConf);
        // console.log("====parseQuery后===", queryParam);
        queryParam.split("&").forEach((param) => {
            const parts = param.replace(/\+/g, " ").split("=");
            const key = decode(parts.shift());
            const val = parts.length > 0 ? decode(parts.join("=")) : null;

            if (isUndefined(res[key])) {
                res[key] = val;
            } else if (Array.isArray(res[key])) {
                res[key].push(val);
            } else {
                res[key] = [res[key], val];
            }
        });
    } catch (error) {
        console.log("解密错误====", error);
        return query;
    }
    return res;
};

// AES解密方法
const decryptWithAES = (cipherText, secretKey, routeConf) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey, routeConf);
    return bytes.toString(CryptoJS.enc.Utf8);
};

// AES加密方法
const encryptWithAES = (text, secretKey, routeConf) => {
    return CryptoJS.AES.encrypt(text, secretKey).toString();
};
