import organizeHttp from "@/api/organize-manager/organizeHttp";
import { Constants } from "@/utils/constants/Constants";
import businessHttp from "@/api/system-manager/business-manager/businessHttp.js";
import { RequestCodeConstants } from "@/utils/constants/RequestCodeConstants";
import appHttp from "@/api/system-manager/app-manager/appHttp.js";
import commonHttp from "@/api/common/commonHttp";

export default {
    /**
     * 行业
     */
    formatterExamIndustryNameListStr(item) {
        if (!item || !item.extJsonObj) {
            return "通用";
        }
        if (!item.extJsonObj.industryList || item.extJsonObj.industryList.length == 0) {
            return "通用";
        }
        const tempList = [];
        const industryList = item.extJsonObj.industryList;
        industryList.map((object) => {
            const str = object.industryName;
            tempList.push(str);
        });
        const resultStr = tempList.join("、");
        return resultStr;
    },
    /**
     * 培训类别
     */
    formatterExamSubjectNameListStr(item) {
        if (!item || !item.extJsonObj) {
            return "通用";
        }
        if (!item.extJsonObj || !item.extJsonObj.subjectList || item.extJsonObj.subjectList.length == 0) {
            return "通用";
        }
        const tempList = [];
        item.extJsonObj.subjectList.map((object) => {
            const str = object.subjectName;
            tempList.push(str);
        });
        const resultStr = tempList.join("、");
        return resultStr;
    },

    /**
     * 岗位
     */
    formatterExamDutyNameListStr(item) {
        if (!item || !item.extJsonObj) {
            return "通用";
        }
        if (!item.extJsonObj || !item.extJsonObj.dutyList || item.extJsonObj.dutyList.length == 0) {
            return "通用";
        }
        const tempList = [];
        item.extJsonObj.dutyList.map((object) => {
            const str = object.dutyName;
            tempList.push(str);
        });
        const resultStr = tempList.join("、");
        return resultStr;
    },
    /**
     * 课件
     */
    formatterExamCoursesListStr(item) {
        if (!item.examExerciseCourses) {
            return "-";
        }
        if (!item.examExerciseCourses || item.examExerciseCourses.length == 0) {
            return "-";
        }
        const tempList = [];
        item.examExerciseCourses.map((object) => {
            const str = object.courseName;
            tempList.push(str);
        });
        const resultStr = tempList.join("、");
        return resultStr;
    },
    /**
     * 标签
     */
    formatterExamSysTagListStr(item) {
        if (!item) {
            return "-";
        }
        if (!item.sysTagDtoList || item.sysTagDtoList.length == 0) {
            return "-";
        }
        const tempList = [];
        item.sysTagDtoList.map((object) => {
            const str = object.tagName;
            tempList.push(str);
        });
        const resultStr = tempList.join("、");
        return resultStr;
    },
    /**
     * 格式化项目数据
     * */
    formatterprojectNamesString(item) {
        let string = "通用";
        if (item.projectNames !== undefined && item.projectNames !== null && item.projectNames != "") {
            string = item.projectNames;
        }
        return string;
    },
    /**
     * 格式化类别数据
     * */
    formattersubjectNamesString(item) {
        let string = "通用";
        if (item.subjectNames !== undefined && item.subjectNames !== null && item.subjectNames != "") {
            string = item.subjectNames;
        }
        return string;
    },
    /**
     * 格式化岗位数据
     * */
    formatterdutyNamesString(item) {
        let string = "通用";
        if (item.dutyNames !== undefined && item.dutyNames !== null && item.dutyNames != "") {
            string = item.dutyNames;
        }
        return string;
    },
    /**
     * 格式化时间数据
     * */
    formatterpublishTimeString(item) {
        let string = "-";
        if (item.publishTime !== undefined && item.publishTime !== null && item.publishTime != "") {
            string = item.publishTime;
        } else {
        }
        return string;
    },
    /**
     * 获取状态
     * */
    getStateString(state) {
        let string;
        if (state === 0) {
            string = "停用";
        } else if (state === 1) {
            string = "草稿";
        } else if (state === 2) {
            string = "待发布";
        } else if (state === 3) {
            string = "已发布";
        } else {
            string = "-";
        }
        return string;
    },
    /**
     * 获取生成状态
     * */
    getGenerateModelString(generateModel) {
        return generateModel === 1 ? "手动生成" : generateModel === 2 ? "自动生成" : "-";
    },
    /**
     * 获取生成方式 lzb 20230829
     * */
    getGenerateTypeString(generateType) {
        return generateType === 1 ? "预先生成" : generateType === 2 ? "考试时生成" : "-";
    },
    /**
     * 获取规则
     * */
    getRuleTypeString(ruleType) {
        let string = "-";
        if (ruleType === 1) {
            string = "按顺序考试（单选题-多选题-判断题）";
        } else if (ruleType === 2) {
            string = "按所有题目乱序考试";
        } else if (ruleType === 3) {
            string = "按题型乱序考试";
        }
        return string;
    },
    /**
     * 获取规则
     * */
    getRuleTypeListString(ruleType, list) {
        let temp = "";
        if (ruleType === 3) {
            const arr = list.split(",");
            if (arr !== undefined && arr !== null && arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    const j = parseInt(arr[i]);
                    temp = (temp.length > 0 ? `${temp},` : "") + this.getaErexercisesTypeString(j);
                }
            } else {
                temp = "-";
            }
            if (temp.length == 0) {
                temp = "-";
            } else {
                temp = `（${temp}）`;
            }
        }
        return temp;
    },
    /**
     * 获取难度
     * */
    getDifficultyDegreeString(difficultyDegree) {
        let string = "-";
        if (difficultyDegree === 1) {
            string = "1星";
        } else if (difficultyDegree === 2) {
            string = "2星";
        } else if (difficultyDegree === 3) {
            string = "3星";
        } else if (difficultyDegree === 4) {
            string = "4星";
        } else if (difficultyDegree === 5) {
            string = "5星";
        }
        return string;
    },
    /**
     * 获取题型
     * */
    getaErexercisesTypeString(exercisesType) {
        let string = "-";
        if (exercisesType === 1) {
            string = "单选题";
        } else if (exercisesType === 2) {
            string = "多选题";
        } else if (exercisesType === 3) {
            string = "判断题";
        }
        return string;
    },
    /**
     * 获取状态
     * */
    getaSceneTypeString(sceneType) {
        let string = "-";
        if (sceneType === 11) {
            string = "新增注册照";
        } else if (sceneType === 12) {
            string = "修改注册照";
        } else if (sceneType === 20) {
            string = "网络学习签到";
        } else if (sceneType === 21) {
            string = "网络学习抽验";
        } else if (sceneType === 22) {
            string = "网络学习签退";
        } else if (sceneType === 30) {
            string = "现场签到";
        } else if (sceneType === 31) {
            string = "现场抽验";
        } else if (sceneType === 32) {
            string = "现场签退";
        } else if (sceneType === 40) {
            string = "考试签到";
        } else if (sceneType === 41) {
            string = "考试抽验";
        } else if (sceneType === 42) {
            string = "考试签退";
        } else if (sceneType === 50) {
            string = "安全管理员签到";
        } else if (sceneType === 51) {
            string = "安全管理员签退";
        } else if (sceneType === 52) {
            string = "考核人员签到";
        } else if (sceneType === 53) {
            string = "考核人员签退";
        } else if (sceneType === 54) {
            string = "授课人签到";
        } else if (sceneType === 55) {
            string = "授课人签退";
        }
        return string;
    },
    /**
     * 试题类型
     * */
    getExercisesTypeList() {
        const exercisesTypeList = [
            {
                value: 1,
                label: "单选题",
            },
            {
                value: 2,
                label: "多选题",
            },
            {
                value: 3,
                label: "判断题",
            },
        ];
        return exercisesTypeList;
    },
    /**
     * 星级
     * */
    getDifficultyDegreeList() {
        const difficultyDegreeList = [
            {
                value: 1,
                label: "1星",
            },
            {
                value: 2,
                label: "2星",
            },
            {
                value: 3,
                label: "3星",
            },
            {
                value: 4,
                label: "4星",
            },
            {
                value: 5,
                label: "5星",
            },
        ];
        return difficultyDegreeList;
    },

    /**
     * 试题基础数据
     * */
    getManualExercisesAccount() {
        // 被选中的数组  题项

        const manualExercisesAccount = {
            // 总题数
            totalCount: 0,
            // 合格分数
            passingScore: 0,
            // 现场考试合格分数
            offlinePassingScore: 0,
            // 总分数
            score: 0,
            // 单选多选判断的题数
            exercisesTypeNums: [0, 0, 0],
            // 判断题统计
            judgeAccounts: [
                { difficultyDegree: 1, count: 0 },
                { difficultyDegree: 2, count: 0 },
                { difficultyDegree: 3, count: 0 },
                { difficultyDegree: 4, count: 0 },
                { difficultyDegree: 5, count: 0 },
            ],
            // 单选题统计
            singleAccounts: [
                { difficultyDegree: 1, count: 0 },
                { difficultyDegree: 2, count: 0 },
                { difficultyDegree: 3, count: 0 },
                { difficultyDegree: 4, count: 0 },
                { difficultyDegree: 5, count: 0 },
            ],
            // 多选统计
            multipleAccounts: [
                { difficultyDegree: 1, count: 0 },
                { difficultyDegree: 2, count: 0 },
                { difficultyDegree: 3, count: 0 },
                { difficultyDegree: 4, count: 0 },
                { difficultyDegree: 5, count: 0 },
            ],
        };

        return manualExercisesAccount;
    },
    /**
     * 试卷场景
     * */
    getExamPaperTypeList(examModel) {
        let one = false;
        let two = false;
        if (examModel === 1) {
            two = true;
        } else if (examModel === 2) {
            one = true;
        }

        const list = [
            {
                label: "网络考试",
                value: "2",
                disabled: two,
            },
            {
                label: "现场考试",
                value: "1",
                disabled: one,
            },
        ];
        return list;
    },
    /**
     * 获取试卷状态
     * */
    getExamStateList() {
        const list = [
            {
                label: "停用",
                value: "0",
            },
            {
                label: "草稿",
                value: "1",
            },
            {
                label: "待发布",
                value: "2",
            },
            {
                label: "已发布",
                value: "3",
            },
        ];
        return list;
    },
    /**
     * 获取默认选项选项
     * */
    getexamExerciseContents() {
        const list = [
            { exercisesOption: "A", content: "", picUrl: "", answer: "", allUrl: "", disable: false },
            { exercisesOption: "B", content: "", picUrl: "", answer: "", allUrl: "", disable: false },
            { exercisesOption: "C", content: "", picUrl: "", answer: "", allUrl: "", disable: false },
            { exercisesOption: "D", content: "", picUrl: "", answer: "", allUrl: "", disable: false },
        ];
        return list;
    },
    /**
     * 试题类型
     * */
    getExamQuestionTypeList() {
        const list = [
            {
                label: "国家规定",
                value: "1",
            },
            {
                label: "地方要求",
                value: "2",
            },
        ];
        return list;
    },
    /**
     * 上传处理试题类型
     * */
    getExamQuestionTypeUpload(examTypeList) {
        let areaType = "";
        if (examTypeList.length == 2) {
            areaType = 3;
        } else if (examTypeList.length == 1) {
            areaType = examTypeList[0];
        }
        return areaType;
    },
    /**
     * 接收处理试题类型  // 处理试题类型   1国家 2本地  3 全选  必选数据 - 暂时没想到好的方法写，后台只返一个数据，我展示需要list
     * */
    getExamQuestionTypeReceive(areaType) {
        let areaTypeList = "";
        if (areaType == 1) {
            areaTypeList = ["1"];
        } else if (areaType == 2) {
            areaTypeList = ["2"];
        } else if (areaType == 3) {
            areaTypeList = ["1", "2"];
        } else {
            areaTypeList = ["1"];
        }
        return areaTypeList;
    },
    /**
     * 上传处理试卷场景
     * */
    getExamPaperTypeUpload(examPaperTypeList) {
        let examPaperType = "";
        if (examPaperTypeList.length == 2) {
            examPaperType = 3;
        } else if (examPaperTypeList.length == 1) {
            examPaperType = examPaperTypeList[0];
        }
        return examPaperType;
    },
    /**
     * 默认值
     * */
    getExamPaperNum(val) {
        if (isNaN(val)) {
            return 0;
        }
        return val;
    },
    /**
     * 默认值
     * */
    getExamPaperNumIsNaN(val) {
        if (isNaN(val)) {
            return true;
        }
        return false;
    },
    /**
     * 接收处理试卷场景
     * */
    getExamPaperTypeReceive(examPaperType) {
        let examPaperTypeList = "";
        if (examPaperType == 1) {
            examPaperTypeList = ["1"];
        } else if (examPaperType == 2) {
            examPaperTypeList = ["2"];
        } else if (examPaperType == 3) {
            examPaperTypeList = ["1", "2"];
        } else {
            examPaperTypeList = ["2"];
        }
        return examPaperTypeList;
    },
    /**
     *  获取试卷考题相关数据
     * */
    getAutoExercisesAccount() {
        const account = {
            // 总题数
            totalCount: 0,
            // 单选多选判断的总题数 用于展示和限制
            exercisesTypeNums: [0, 0, 0],
            // 合格分数
            passingScore: 0,
            // 已选总题数
            chooseTotalCount: 0,
            // 已选总分数
            score: 0,
            // 试题组成规则 1只有数量分数 2 题型数量分数3 题型难度数量分数
            exerciseRuleType: 2,
            // 已选题型的数量
            chooseExerciseTypeNums: [0, 0, 0],
            // 已选分数统计 单选 多选 判断
            chooseTypeScore: [0, 0, 0],
            // 通用模式下的数量与分数
            commonAccount: { chooseCount: 0, score: 1 },
            // 根据题型选择数量与分数 单选 多选 判断
            exerciseTypeAccount: [
                { chooseCount: 0, score: 1 },
                { chooseCount: 0, score: 1 },
                { chooseCount: 0, score: 1 },
            ],
            // 判断题统计
            judgeAccounts: [
                { difficultyDegree: 1, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 2, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 3, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 4, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 5, count: 0, chooseCount: 0, score: 1 },
            ],
            // 单选题统计
            singleAccounts: [
                { difficultyDegree: 1, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 2, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 3, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 4, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 5, count: 0, chooseCount: 0, score: 1 },
            ],
            // 多选统计
            multipleAccounts: [
                { difficultyDegree: 1, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 2, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 3, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 4, count: 0, chooseCount: 0, score: 1 },
                { difficultyDegree: 5, count: 0, chooseCount: 0, score: 1 },
            ],
        };
        return account;
    },
    /**
     * 星级
     * */
    getSingleAccounts() {
        const singleAccounts = [
            { difficultyDegree: 1, count: 0, chooseCount: 0, score: 1 },
            { difficultyDegree: 2, count: 0, chooseCount: 0, score: 1 },
            { difficultyDegree: 3, count: 0, chooseCount: 0, score: 1 },
            { difficultyDegree: 4, count: 0, chooseCount: 0, score: 1 },
            { difficultyDegree: 5, count: 0, chooseCount: 0, score: 1 },
        ];

        return singleAccounts;
    },
    /**
     * 分数计算  - 编辑
     * */
    getInfoManualExercisesAccount(data) {
        const manualExercisesAccount = this.getManualExercisesAccount();

        // 总题数
        manualExercisesAccount.totalCount = data.examPaperExercises.length;
        manualExercisesAccount.passingScore = data.passingScore;
        manualExercisesAccount.offlinePassingScore = data.offlinePassingScore;
        manualExercisesAccount.score = data.score;

        for (let i = 0; i < data.examPaperScopeCounts.length; i++) {
            const item = data.examPaperScopeCounts[i];
            const num = data.examPaperScopeCounts[i].difficultyDegree - 1;
            if (item.exercisesType === 1) {
                // 单选
                manualExercisesAccount.exercisesTypeNums[0] += item.count;
                manualExercisesAccount.singleAccounts[num].count += item.count;
            } else if (item.exercisesType === 2) {
                // 多选
                manualExercisesAccount.exercisesTypeNums[1] += item.count;
                manualExercisesAccount.multipleAccounts[num].count += item.count;
            } else if (item.exercisesType === 3) {
                // 判断
                manualExercisesAccount.exercisesTypeNums[2] += item.count;
                manualExercisesAccount.judgeAccounts[num].count += item.count;
            }
        }

        return manualExercisesAccount;
    },

    /**
     * 分数计算  编辑有试题统计给弹框展示
     * */
    getStatisticsExercise(selectList, netScale, locationScale) {
        // 被选中的数组  题项

        if (netScale === null || netScale === undefined) {
            netScale = 0;
        }
        if (locationScale === null || locationScale === undefined) {
            locationScale = 0;
        }
        const manualExercisesAccount = this.getManualExercisesAccount();

        if (selectList !== undefined && selectList !== null && selectList !== "undefined" && selectList.length > 0) {
            for (let i = 0; i < selectList.length; i++) {
                const exercise = selectList[i];
                const num = exercise.difficultyDegree - 1;
                manualExercisesAccount.totalCount += 1;
                manualExercisesAccount.score += exercise.score;
                manualExercisesAccount.passingScore = (manualExercisesAccount.score * netScale).toFixed(1);
                manualExercisesAccount.offlinePassingScore = (manualExercisesAccount.score * locationScale).toFixed(1);
                if (exercise.exercisesType === 1) {
                    // 单选
                    manualExercisesAccount.exercisesTypeNums[0] = manualExercisesAccount.exercisesTypeNums[0] + 1;
                    manualExercisesAccount.singleAccounts[num].count++;
                } else if (exercise.exercisesType === 2) {
                    // 多选
                    manualExercisesAccount.exercisesTypeNums[1] = manualExercisesAccount.exercisesTypeNums[1] + 1;
                    manualExercisesAccount.multipleAccounts[num].count++;
                } else if (exercise.exercisesType === 3) {
                    // 判断
                    manualExercisesAccount.exercisesTypeNums[2] = manualExercisesAccount.exercisesTypeNums[2] + 1;
                    manualExercisesAccount.judgeAccounts[num].count++;
                }
            }
        }

        return manualExercisesAccount;
    },

    /**
     * 数组处理
     * */
    getprojectListIds(list) {
        const temp = [];
        if (list !== undefined && list !== null && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                temp.push(item.projectId);
            }
        }
        return temp;
    },
    /**
     * 获取类别ids
     * */
    getsubjectListIds(list) {
        const temp = [];
        if (list !== undefined && list !== null && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                temp.push(item.subjectId);
            }
        }
        return temp;
    },
    /**
     * 获取类别ids
     * */
    getCeartsubjectListIds(list) {
        const temp = [];
        if (list !== undefined && list !== null && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                temp.push({ industryProjectSubjectId: item.id });
            }
        }
        return temp;
    },
    /**
     * 获取岗位ids
     * */
    getdutyCodeListIds(list) {
        const temp = [];
        if (list !== undefined && list !== null && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                temp.push({ industryProjectSubjectDutyId: item.id });
            }
        }
        return temp;
    },
    /**
     * 获取岗位list string
     * */
    getdutyCodeListString(list) {
        let temp = "";
        if (list !== undefined && list !== null && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                temp = (temp.length > 0 ? `${temp},` : "") + item.dutyName;
            }
        } else {
            temp = "-";
        }
        if (temp.length == 0) {
            temp = "-";
        }
        return temp;
    },
    /**
     * 获取类别list string
     * */
    getsubjectListString(list) {
        let temp = "";
        if (list !== undefined && list !== null && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                temp = (temp.length > 0 ? `${temp},` : "") + item.subjectName;
            }
        } else {
            temp = "-";
        }
        if (temp.length == 0) {
            temp = "-";
        }
        return temp;
    },

    /**
     * 根据规则 生成的最大试卷套数
     * */
    handlehandleMaxGenerateCount(exerciseRuleType, autoExercisesAccount) {
        let sumCount = 0; // 已选择对应的类型总数
        let sumchooseCount = 0; // 对应类型总数选择的个数

        if (exerciseRuleType === 1) {
            sumchooseCount = autoExercisesAccount.chooseTotalCount;
            sumCount = autoExercisesAccount.totalCount;
        } else if (exerciseRuleType === 2) {
            if (autoExercisesAccount.exerciseTypeAccount[0].chooseCount > 0) {
                sumCount += autoExercisesAccount.exercisesTypeNums[0];
                sumchooseCount += autoExercisesAccount.exerciseTypeAccount[0].chooseCount;
            }
            if (autoExercisesAccount.exerciseTypeAccount[1].chooseCount > 0) {
                sumCount += autoExercisesAccount.exercisesTypeNums[1];
                sumchooseCount += autoExercisesAccount.exerciseTypeAccount[1].chooseCount;
            }
            if (autoExercisesAccount.exerciseTypeAccount[2].chooseCount > 0) {
                sumCount += autoExercisesAccount.exercisesTypeNums[2];
                sumchooseCount += autoExercisesAccount.exerciseTypeAccount[2].chooseCount;
            }
        } else if (exerciseRuleType === 3) {
            autoExercisesAccount.chooseExerciseTypeNums[0];

            for (let i = 0; i < autoExercisesAccount.singleAccounts.length; i++) {
                const item = autoExercisesAccount.singleAccounts[i];
                if (item.count > 0) {
                    if (item.chooseCount > 0) {
                        sumCount += item.count;
                        sumchooseCount += item.chooseCount;
                    }
                }
            }
            for (let i = 0; i < autoExercisesAccount.multipleAccounts.length; i++) {
                const item = autoExercisesAccount.multipleAccounts[i];
                if (item.count > 0) {
                    if (item.chooseCount > 0) {
                        sumCount += item.count;
                        sumchooseCount += item.chooseCount;
                    }
                }
            }
            for (let i = 0; i < autoExercisesAccount.judgeAccounts.length; i++) {
                const item = autoExercisesAccount.judgeAccounts[i];
                if (item.count > 0) {
                    if (item.chooseCount > 0) {
                        sumCount += item.count;
                        sumchooseCount += item.chooseCount;
                    }
                }
            }
        }

        let maxCount = 1;
        let allCount = 0;
        // 1.总题数 / 选择的试题数量 = 生成的试卷数量(取整)
        allCount = sumchooseCount <= 0 ? 0 : parseInt(sumCount / sumchooseCount);
        // 2.如果试卷数量大于(总题数 * 0.3)时, 取此数量(总题数*0.3)为最大值
        if (allCount > sumCount * 0.3) {
            maxCount = sumCount <= 0 ? 0 : parseInt(sumCount * 0.3);
        } else {
            // 3. 试卷数量小于(总题数 * 0.3)时，取的试卷数量为最大值
            maxCount = allCount;
        }

        if (maxCount <= 0) {
            maxCount = 1;
        }
        console.log("allCount:", allCount, "maxCount:", maxCount);
        return maxCount;
    },

    /**
     * 是否展示网络考试
     * */
    handleExamModelIsShow(val, examModel) {
        if (examModel.length == 1) {
            if (examModel[0] === val) {
                return true;
            } else {
                return false;
            }
        } else if (examModel.length > 1) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 获取上级组织
     */
    findOrgPaperList(orgData, orgId) {
        const query = { orgId, employMode: "EXAM_MODE" };

        const that = this;

        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            if (resolve) {
                resolve({});
            }
            // organizeHttp.getGrouparerList(query).then((response) => {
            //     if (response.success && response.data) {
            //         response.data.forEach((item) => {
            //             const examItem = {
            //                 orgId: item.orgId,
            //                 orgName: item.orgName,
            //             };
            //             orgData.push(examItem);
            //         });
            //     }
            //     if (resolve) {
            //         resolve(response);
            //     }
            // });
        });
    },
    /**
     * 获取上级组织 - 少量可检索 - 默认查询上级
     */
    findNewLessOrgPaperList(orgData, orgId, orgName, hierarchyCode = "MANAGE_SUPERIOR") {
        const query = { orgId, hierarchyCode, orgName };

        const that = this;

        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            organizeHttp.getExamGrouparerList(query).then((response) => {
                if (response.success && response.data) {
                    response.data.forEach((item) => {
                        const examItem = {
                            orgId: item.orgId,
                            orgName: item.orgName,
                        };
                        orgData.push(examItem);
                    });
                }
                if (resolve) {
                    resolve(response);
                }
            });
        });
    },
    /**
     * 获取上级 + 下级 组织 - 少量可检索 - 试卷里面查试题
     */
    findNewUpperLessOrgPaperList(orgData, orgId, orgName, hierarchyCode = "MANAGE_SUPERIOR_AND_LOWER") {
        const orgManagerTypeCodeListForm = [
            Constants.organizationType.ORGTYPE_HEAD_OFFICE,
            Constants.organizationType.ORGTYPE_SUPPERVISE,
            Constants.organizationType.ORGTYPE_COMPANY,
            Constants.organizationType.ORGTYPE_ORGAN,
        ];
        // 20220412 ye 当前接口不跟orgId相关，后台根据token权限查询
        const query = { orgId, orgManagerTypeCodeListForm, hierarchyCode, notCheckLoginInfo: 0, orgName };

        const that = this;

        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            organizeHttp.getExamGrouparerList(query).then((response) => {
                if (response.success && response.data) {
                    response.data.forEach((item) => {
                        const examItem = {
                            orgId: item.orgId,
                            orgName: item.orgName,
                        };
                        orgData.push(examItem);
                    });
                }
                if (resolve) {
                    resolve(response);
                }
            });
        });
    },

    /**
     * 获取试题关键字
     */
    findNewExamPaperlabelsList(allLabels, orgId) {
        const requestParam = {
            industryCode: "", // 行业代码
            trainingModelCode: "", // 培训模式编码
            dutyCode: "", // 岗位代码
            parameterCode: RequestCodeConstants.TB_EXAM_LABEL_TYPE, // 操作代码（页面上的操作代码）
            orgId, // 当前所属组织Id
        };

        const that = this;

        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            appHttp.initQuerParam(requestParam).then((response) => {
                if (response.success && response.data) {
                    const data = response.data;
                    const typeListStr = data.content;
                    const typeList = JSON.parse(typeListStr);
                    // console.log('标签列表',typeList);
                    // 赋值
                    if (typeList != null && typeList != undefined) {
                        typeList.forEach((object) => {
                            const item = {
                                name: object.parameterName,
                                select: false,
                            };
                            allLabels.push(item);
                        });
                    }
                }
                if (resolve) {
                    resolve(response);
                }
            });
        });
    },
    /**
     * 获取试卷参数配置 type : 1: 网络考试时长 2:网络考试合格分数率  3:现场考试时长  4: 现场考试合格分数率
     */
    findExamPaperParamList(paramList, orgId, type) {
        let parameterCode = "";
        if (type === 1) {
            // 网络考试时长
            parameterCode = RequestCodeConstants.TB_EXAM_ONLINE_EXAM_DURATION;
        } else if (type === 2) {
            // 网络考试合格分数
            parameterCode = RequestCodeConstants.TB_EXAM_ONLINE_PASSING_SCORE;
        } else if (type === 3) {
            // 现场考试时长
            parameterCode = RequestCodeConstants.TB_EXAM_OFFLINE_EXAM_DURATION;
        } else if (type === 4) {
            // 现场考试合格分数
            parameterCode = RequestCodeConstants.TB_EXAM_OFFLINE_PASSING_SCORE;
        }
        const requestParam = {
            industryCode: "", // 行业代码
            trainingModelCode: "", // 培训模式编码
            dutyCode: "", // 岗位代码
            parameterCode, // 操作代码（页面上的操作代码）
            orgId, // 当前所属组织Id
        };

        const that = this;

        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            appHttp.initQuerParam(requestParam).then((response) => {
                if (response.success && response.data) {
                    const data = response.data;
                    const typeListStr = data.content;
                    const typeList = JSON.parse(typeListStr);
                    // console.log('标签列表',typeList);
                    // 赋值

                    if (type === 2 || type === 4) {
                        paramList = typeList;
                    } else if (typeList != null && typeList != undefined) {
                        typeList.forEach((object) => {
                            const item = {
                                parameterName: object.parameterName,
                                parameterCode: object.parameterCode,
                                select: false,
                            };
                            paramList.push(item);
                        });
                    }
                }
                if (resolve) {
                    resolve(paramList);
                }
            });
        });
    },
    /**
     * 获取岗位和类别
     */
    findDutySubList(subIds, dutyIds, procetIds, orgId, induIds) {
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            // 请求参数处理
            const param = { orgId };
            const that = this;
            businessHttp.selectConditionData(param).then((response) => {
                const success = response.success;
                const temp = [];
                if (success) {
                    const subjectList = response.data.subjectList;
                    const dutyList = response.data.dutyList;
                    const projectList = response.data.projectList;
                    const industryList = response.data.industryList;

                    if (subjectList !== null && subjectList !== undefined && subjectList.length > 0) {
                        subjectList.forEach((item) => {
                            subIds.push(item);
                        });
                    }
                    if (projectList !== null && projectList !== undefined && projectList.length > 0) {
                        projectList.forEach((item) => {
                            procetIds.push(item);
                        });
                    }
                    if (dutyList !== null && dutyList !== undefined && dutyList.length > 0) {
                        dutyList.forEach((item) => {
                            dutyIds.push(item);
                        });
                    }

                    if (industryList !== null && industryList !== undefined && industryList.length > 0) {
                        industryList.forEach((item) => {
                            induIds.push(item);
                        });
                    }
                }
            });
        });
    },
    // ========================================= 2020-12-01  行业类别岗位获取 ========================================
    /**
     * 获取类别
     */
    getSubjectList(list, orgId) {
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            // 请求参数处理
            const param = { orgId };
            const that = this;
            commonHttp.getCommonSubjectList(param).then((response) => {
                const success = response.success;
                const temp = [];
                if (success) {
                    const data = response.data;

                    if (data !== null && data !== undefined && data.length > 0) {
                        data.forEach((item) => {
                            list.push(item);
                        });
                    }
                }
            });
        });
    },
    /**
     * 获取行业
     */
    getIndutryList(list, orgId) {
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            // 请求参数处理
            const param = { orgId };
            const that = this;
            commonHttp.getCommonIndustryList(param).then((response) => {
                const success = response.success;
                const temp = [];
                if (success) {
                    const data = response.data;

                    if (data !== null && data !== undefined && data.length > 0) {
                        data.forEach((item) => {
                            list.push(item);
                        });
                    }
                }
            });
        });
    },
    /**
     * 获取岗位
     */
    getDutyList(list, orgId, indutryCodeList) {
        // 点击确定之后 -回调
        return new Promise((resolve, reject) => {
            // 请求参数处理
            const param = { orgId: orgId, industryList: indutryCodeList, concatDutyAndIndustry: 0 };
            const that = this;
            commonHttp.getCommonDutyList(param).then((response) => {
                const success = response.success;
                const temp = [];
                if (success) {
                    const data = response.data;

                    if (data !== null && data !== undefined && data.length > 0) {
                        data.forEach((item) => {
                            list.push(item);
                        });
                    }
                }
            });
        });
    },
    // ========================================= 2020-11-24 新版本行业类别 ========================================
    /**
     * 多类别展示规范
     * @param extJsonObj
     */
    formatExtSubjectListToStr(extJsonObj) {
        if (extJsonObj && extJsonObj.subjectList) {
            let subjectStr = "";
            extJsonObj.subjectList.forEach((item) => {
                if (subjectStr === "") {
                    subjectStr = `${item.subjectName}`;
                } else {
                    subjectStr = `${subjectStr},${item.subjectName}`;
                }
            });
            return subjectStr;
        } else {
            return "通用";
        }
    },
    /**
     * 多行业展示规范
     * @param extJsonObj
     */
    formatExtIndustryListToStr(extJsonObj) {
        if (extJsonObj && extJsonObj.industryList) {
            let subjectStr = "";
            extJsonObj.industryList.forEach((item) => {
                if (subjectStr === "") {
                    subjectStr = `${item.industryName}`;
                } else {
                    subjectStr = `${subjectStr},${item.industryName}`;
                }
            });
            return subjectStr;
        } else {
            return "通用";
        }
    },
    /**
     * 多岗位展示
     * @param extJsonObj
     * @returns {string}
     */
    formatExtDutyListToStr(extJsonObj, enableNoData = false) {
        if (extJsonObj && extJsonObj.dutyList) {
            let dutyStr = "";
            extJsonObj.dutyList.forEach((item) => {
                if (dutyStr === "") {
                    dutyStr = `${item.dutyName}`;
                } else {
                    dutyStr = `${dutyStr},${item.dutyName}`;
                }
            });
            return dutyStr;
        } else {
            return enableNoData ? "无" : "通用";
        }
    },

    /**
     * 多类别转数组
     * @param list
     */
    formatSubjectListToStringArray(list) {
        const subArr = [];
        if (list && list.length > 0) {
            list.forEach((item) => {
                const subStr = `${item.subjectName}`;
                subArr.push(subStr);
            });
        }
        return subArr;
    },
    /**
     * 多岗位转数组
     * @param list
     */
    formatDutytListToStringArray(list) {
        const dutyArr = [];
        if (list != null && list != undefined && list && list.length > 0) {
            list.forEach((item) => {
                const subStr = `${item.dutyName}`;
                dutyArr.push(subStr);
            });
        }
        return dutyArr;
    },

    /**
     * 获取多岗位第一个类别字符串
     * @param list
     */
    formatFirstDutyStr(list) {
        let dutyStr = "";
        if (list != null && list != undefined && list && list.length > 0) {
            const item = list[0];
            dutyStr = `${item.dutyName}`;
        }
        return dutyStr == "" ? "通用" : dutyStr;
    },
};
