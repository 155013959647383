import "@babel/polyfill";
import Es6Promise from "es6-promise";

// 导入图标
import "@/assets/icons";

// 所有CSS
import "./assets/scss/index.scss";
// import "element-ui/lib/theme-chalk/index.css";

// 核心包
import Vue from "vue";
import ElementUI from "element-ui";
import Vuex from "vuex";
import Print from "@/plugs/print.js";
import VueDND from "awe-dnd"; // 拖拽
import VueDriver from "@tibi/vue-driver";
import App from "./App.vue";

// 后台管理框架

// 系统封装类
import store from "./store";
import "./permission";
import * as directives from "./directives";

import router from "./router.js";
import * as filters from "./filters";
import "./utils/mixins";
import "./utils/global/flexible.js";

import "./utils/global/global.js";
import "./utils/formatter-global/FormatterGlobal.js";

import VueAwesomeSwiper from "vue-awesome-swiper";

import dataV from "@jiaminghi/data-view";

// 流程图
import jsPlumb from "jsplumb";

// 埋点
import TibiMatomo from "@tibi/tibi-matomo-plug-in";
// 预览图片
import xhImagePreview from "xh-image-preview";

Vue.use(xhImagePreview);
// 正式环境有效 lzb
const IS_PROD = ["production"].includes(process.env.NODE_ENV);
if (IS_PROD) {
    TibiMatomo.init({ open: true, host: process.env.VUE_APP_API_USER_ACTION_URL });
}

// 图片懒加载
import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad, {
    preLoad: 1.3,
});

// TibiMatomo.init({open: true, host: process.env.VUE_APP_API_USER_ACTION_URL});

Es6Promise.polyfill();
Vue.use(VueDriver);
Vue.use(dataV);

Vue.use(VueAwesomeSwiper);
// 注册
Vue.use(Print);
Vue.use(VueDND);
Vue.use(Vuex);
Vue.use(ElementUI);
Vue.use(jsPlumb);

// 过滤器统一处理加载
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

// 过滤器统一处理加载
Object.keys(directives).forEach((key) => {
    Vue.directive(key, directives[key]);
});

// 挂载
Vue.prototype.$EventBus = new Vue();
Vue.config.productionTip = false;
Vue.prototype.$jsPlumb = jsPlumb.jsPlumb;

// // lzb 错误日志收集
// Vue.config.errorHandler = function (err, vm, info) {
//     const errorObj = {
//         error: err,
//         vm,
//         info,
//         url: window.location.href,
//     };
//     store.dispatch('errorLog/addVueErrorLog', errorObj);
// };

import htmlToPdf from "@/utils/htmlToPdf";
Vue.use(htmlToPdf);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
