/** ************************************时间格式化处理*********************************** */

import moment from "moment/moment";

export default {
    format: {
        YYYY_MM_DD_HH_MM_SS: "yyyyMMddhhmmss",
        YYYY_MM_DD: "yyyyMMdd",
        HH_MM_SS: "hhmmss",
    },
    dateFtt: (fmt, date) => {
        if (date == null || date == undefined) {
            date = new Date();
        }
        const o = {
            "M+": date.getMonth() + 1, // 月份
            "d+": date.getDate(), // 日
            "h+": date.getHours(), // 小时
            "m+": date.getMinutes(), // 分
            "s+": date.getSeconds(), // 秒
            "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
            S: date.getMilliseconds(), // 毫秒
        };

        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
        for (const k in o)
            if (new RegExp(`(${k})`).test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
        return fmt;
    },
    /**
     * 日期转时间戳
     * @param strtime
     * @returns {number}
     * @constructor
     */
    YMDToDate(strtime) {
        if (!strtime) {
            return null;
        }
        const date = new Date(strtime);
        const time = Date.parse(date) / 1000;

        return time;
    },
    /**
     * 将分钟转为HH：mm
     * @param minute
     */
    minuteToHHmm(minute) {
        if (minute === "" || minute === null || minute === undefined) {
            return "";
        }
        // console.log(minute%60);
        // console.log(minute/60);
        const h = parseInt(minute / 60);
        const hh = h > 9 ? h : `0${h}`;
        const m = minute % 60;
        const mm = m > 9 ? m : `0${m}`;

        return `${hh}:${mm}`;
    },
    /**
     * 当前年月日
     * @returns {string}
     */
    getNowYearMonthDay() {
        const time = new Date();

        return this.dateFtt("yyyy-MM-dd", time);
    },
    /**
     * 将字符串hh:mm格式的实际转为分钟
     * @param hhmm
     * @returns {number}
     */
    hhmmToMinute(hhmm) {
        if (hhmm === "" || hhmm === null || hhmm === undefined) {
            return 0;
        }
        const time = hhmm.split(":");

        return parseInt(time[0]) * 60 + parseInt(time[1]);
    },
    /**
     * 获取某日期days天后的日期
     * @param startDate
     * @param days
     */
    addDays(startDateString, days) {
        const time = this.DateAdd("d", days, new Date(startDateString.replace(/-/g, "/")));

        return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    },
    /**
     * 增加某个月
     * @param startDate
     * @param months
     */
    addMonths(startDateString, months) {
        const time = this.DateAdd("m", months, new Date(startDateString));

        return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    },
    /**
     * 当前日期
     * @returns {string}
     */
    getNowFormatDate() {
        const time = new Date();

        return this.dateFtt("yyyy-MM-dd", time);
    },

    /**
     * 获取当前天的下一天
     */
    getNowNextDateStr() {
        const newDate = this.getNowNextDate();
        return this.dateFtt("yyyy-MM-dd", newDate);
    },

    /**
     * 获取当前天的下一天
     */
    getNowNextDate() {
        const sourceDate = new Date();
        const day = sourceDate.getDate();
        const year = sourceDate.getFullYear();
        const month = sourceDate.getMonth();
        const nextDay = day + 1;
        const newDate = new Date(year, month, nextDay);
        return newDate;
    },

    /**
     * 比较日期
     * @param date1
     * @param date2
     *   dateTime1 > dateTime2  1
     *    dateTime1 = dateTime2  0
     *     dateTime1 < dateTime2  -1
     */
    compareTwoDate(date1, date2) {
        console.log("compareTwoDate", date1, date2);
        const dateTime1 = date1.getTime();
        const dateTime2 = date2.getTime();
        if (dateTime1 > dateTime2) {
            return 1;
        }
        if (dateTime1 === dateTime2) {
            return 0;
        }
        return -1;
    },

    /**
     * 获取当月最后一天
     * @param dateString
     */
    getMonthLastDay(dateString) {
        const date1 = new Date(dateString);
        const time = new Date(date1.getFullYear(), date1.getMonth() + 1, 0);

        return this.dateFtt("yyyy-MM-dd", time);
    },

    /**
     * 当前月的上一个月
     * isScreeningColumn 是否是筛选栏日期 可传可不传  格式需要 2000-01 月份不足10需要0填充  2020-02-19 yxl
     */
    getLastMonth(isScreeningColumn) {
        const firstDateStr = `${this.getCurMonth()}-01`;
        let date = new Date(firstDateStr);

        date.setMonth(date.getMonth() - 1);

        // console.log("上月",this.dateFtt('yyyy-MM-dd', date))

        const year = date.getFullYear();
        let month = date.getMonth() + 1;

        if (isScreeningColumn && month > 0 && month < 10) {
            month = `0${month}`;
        }
        const dateString = `${year}-${month}`;

        return dateString;
    },
    getCurMonth() {
        const date = new Date();

        date.setMonth(date.getMonth());

        const year = date.getFullYear();
        let month = date.getMonth() + 1;

        if (month > 0 && month < 10) {
            month = `0${month}`;
        }
        const dateString = `${year}-${month}`;

        return dateString;
    },
    /**
     * 根据时间字符串获取月份
     */
    getMothByDateStr(dateStr) {
        if (!dateStr) {
            return -1;
        }
        const date = new Date(dateStr);
        const month = date.getMonth() + 1;
        console.log("getMothByDateStr", month);
        return month;
    },
    /**
     * 根据时间字符串获取月份
     */
    getMothByDate(date) {
        if (!date) {
            return -1;
        }
        const month = date.getMonth() + 1;
        console.log("getMothByDate", month);
        return month;
    },
    /**
     * 根据时间字符串获取年份
     */
    getYearByDateStr(dateStr) {
        if (!dateStr) {
            return -1;
        }
        const date = new Date(dateStr);
        const month = date.getFullYear();
        console.log("getYearByDateStr", month);
        return month;
    },
    /**
     * 获取季度最后一天
     * @param dateString
     */
    getQuarterLastDay(dateString) {
        const date1 = new Date(dateString);
        const month = date1.getMonth() + 1;
        const year = date1.getFullYear();

        if (month <= 3) {
            return `${year}-03-31`;
        } else if (month <= 6) {
            return `${year}-06-30`;
        } else if (month <= 9) {
            return `${year}-09-30`;
        } else {
            return `${year}-12-31`;
        }
    },
    /**
     * 获取当年最后一天
     * @param dateString
     */
    getYearLastDay(dateString) {
        const date1 = new Date(dateString);
        const year = date1.getFullYear();

        return `${year}-12-31`;
    },

    /**
     * 获取当月第一天到最后一天
     */
    getCurrentMonths() {
        const now = new Date(); // 当前时间
        const year = now.getFullYear(); // 当前年份
        const month = now.getMonth() + 1; // js获取到的是月份是 0-11 所以要加1
        const nextMonthFirstDay = new Date([year, month + 1, 1].join("-")).getTime(); // 下月第一天的时间戳
        const oneDay = 1000 * 24 * 60 * 60;
        const monthLast = new Date(nextMonthFirstDay - oneDay).getDate(); // 当月的最后一天
        const beg = `${year}-${month}-01`; // 当月第一天
        const end = `${year}-${month}-${monthLast}`; // 当月最后一天

        return [beg, end];
    },
    /**
     * 获取当前日的下月的的当前日
     */
    getCurrentDayNextMonthDayTime() {
        const now = new Date(); // 当前时间
        let year = now.getFullYear(); // 当前年份
        const month = now.getMonth(); // js获取到的是月份是 0-11 所以要加1
        const day = now.getDate();
        let nextMonth = month + 1;
        // 如果超过了月份
        if (nextMonth >= 12) {
            nextMonth = 1;
            year = year + 1;
        }

        const resultDate = new Date(year, nextMonth, day, 23, 59, 59);
        const str = this.dateFtt("yyyy-MM-dd hh:mm:ss", resultDate);
        return str;
    },
    /**
     *
     * @param interval 单位 y 年 q 季度 m 月 w周 d 天....
     * @param number 值
     * @param date 初始时间
     * @returns {*}
     * @constructor
     */
    DateAdd(interval, number, date) {
        switch (interval) {
            case "y": {
                date.setFullYear(date.getFullYear() + number);
                return date;
            }
            case "q ": {
                date.setMonth(date.getMonth() + number * 3);
                return date;
            }
            case "M": {
                date.setMonth(date.getMonth() + number);
                return date;
            }
            case "w": {
                date.setDate(date.getDate() + number * 7);
                return date;
            }
            case "d": {
                date.setDate(date.getDate() + number);
                return date;
            }
            case "h": {
                date.setHours(date.getHours() + number);
                return date;
            }
            case "m": {
                date.setMinutes(date.getMinutes() + number);
                return date;
            }
            case "s": {
                date.setSeconds(date.getSeconds() + number);
                return date;
            }
            default: {
                date.setDate(date.getDate() + number);
                return date;
            }
        }
    },

    getNowInDateBetwen(a, b, x, y) {
        const times1 = [];
        const times2 = [];

        if (a < b) {
            // 未跨天
            times1.push([a, b]);
        } else {
            // 跨天
            times1.push([a, "24:00"], ["00:00", b]);
        }

        if (x < y) {
            times2.push([x, y]);
        } else {
            times2.push([x, "24:00"], ["00:00", y]);
        }

        let flag = false;
        // 循环比较时间段是否冲突

        for (let i = 0; i < times1.length; i++) {
            if (flag) break;
            for (let j = 0; j < times2.length; j++) {
                if (check(times1[i][0], times1[i][1], times2[j][0], times2[j][1])) {
                    flag = true;
                    break;
                }
            }
        }
        if (flag) {
            // alert("发生冲突");
            return false;
        } else {
            // alert("没有冲突");
            return true;
        }

        function check(a, b, x, y) {
            if (y < a || b < x) {
                return false;
            } else {
                return true;
            }
        }
    },

    /*
     *  获取当前日期的字符串yyyyMMdd
     * */
    getCurrentDatePathStr() {
        const str = this.dateFtt("yyyyMMdd", new Date());

        return str;
    },

    /**
     * 获取当前的时间戳
     */
    getCurrentFullDateStr() {
        const str = this.dateFtt("yyyyMMddhhmmss", new Date());
        return str;
    },

    /**
     * 获取当前时间的字符串
     */
    getCurrentTimeStr() {
        const str = this.dateFtt("yyyy-MM-dd hh:mm:ss", new Date());

        return str;
    },
    /**
     * 格式化时间字符串
     */
    getDateTimeString(date) {
        const str = this.dateFtt("yyyy-MM-dd hh:mm:ss", date);

        return str;
    },
    /**
     * 获取当前时间的23:59:59
     */
    getDateLastDateTimeStr(date) {
        const str = this.dateFtt("yyyy-MM-dd", date);
        return `${str} 23:59:59`;
    },
    /**
     * 获取当前时间的00:00:00
     */
    getDateStartDateTimeStr(date) {
        if (!date) date = new Date();
        const str = this.dateFtt("yyyy-MM-dd", date);
        return `${str} 00:00:00`;
    },
    /**
     * 获取当前时间的日期
     */
    getCurrentDateStr() {
        const str = this.dateFtt("yyyy-MM-dd", new Date());

        return str;
    },
    /**
     * 获取当前年月
     * @returns {*}
     */
    getCurrentYearMonth() {
        const str = this.dateFtt("yyyy-MM", new Date());

        return str;
    },
    /**
     * 获取当前年
     * @returns {*}
     */
    getCurrentYearTime() {
        const str = this.dateFtt("yyyy", new Date());

        return str;
    },
    /**
     *
     */
    getWeekDate() {
        const now = new Date();
        const day = now.getDay();
        const weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");

        if (day < weeks.length) {
            const week = weeks[day];

            return week;
        }
    },

    /**
     * 获取零点日期
     */
    getZeroDateStr() {
        return `${this.getCurrentDateStr()} 00:00:00`;
    },

    /**
     * 获取当前年
     */
    getCurrentYear() {
        return this.dateFtt("yyyy", new Date());
    },
    /**
     * 获取当前月
     */
    getCurrentMonth() {
        const now = new Date();
        const month = now.getMonth() + 1;

        return month;
    },
    /**
     * 获取当前日
     */
    getCurrentDay() {
        const now = new Date();
        const day = now.getDate();

        return day;
    },
    /**
     * 获取标准的日字符串
     */
    getFormatterCurrentDay() {
        const now = new Date();
        const day = now.getDate();

        if (day < 10) {
            const result = `0${day}`;
            return String(result);
        } else {
            return String(day);
        }
    },
    /**
     * 获取标准的月份字符串
     */
    getFormatterMonth(month) {
        if (!month) {
            return "";
        }
        const monthStr = String(month);
        const monthNum = parseInt(monthStr);

        if (monthNum < 10) {
            const result = `0${monthNum}`;
            return String(result);
        } else {
            return String(monthNum);
        }
    },

    getDayNumOfMonth(Year, Month) {
        return 32 - new Date(Year, Month - 1, 32).getDate();
    },
    /*
     *  处理时间
     * */
    formatterTimeShowStr(val) {
        const time = this.YMDToDate(val) * 1000;
        const soureDate = new Date(val);

        if (time) {
            const newData = Date.parse(new Date());
            const diffTime = Math.abs(newData - time);

            if (diffTime >= 365 * 24 * 3600 * 1000) {
                // 去年以及去年之前：显示年月日
                const dateT = new Date(time);
                const y = dateT.getFullYear();
                let m = dateT.getMonth() + 1;

                m = m < 10 ? `0${m}` : m;
                let d = dateT.getDate();

                d = d < 10 ? `0${d}` : d;
                return `${y}-${m}-${d}`;
            } else if (diffTime > 2 * 24 * 3600 * 1000) {
                // //注释(今年以内到昨天之前：显示年月);
                const dateT = new Date(time);
                const y = dateT.getFullYear();
                let m = dateT.getMonth() + 1;

                m = m < 10 ? `0${m}` : m;
                return `${y}-${m}`;
            } else if (diffTime > 24 * 3600 * 1000 && diffTime < 2 * 24 * 3600 * 1000) {
                // 昨天：显示昨天 时分
                const dateT = new Date(time);
                let h = dateT.getHours();

                h = h < 10 ? `0${h}` : h;
                let minute = dateT.getMinutes();

                minute = minute < 10 ? `0${minute}` : minute;
                const padding = new Date().getDay() - soureDate.getDay();

                if (padding > 0) {
                    return `昨天（${h}:${minute}）`;
                } else {
                    return `今天（${h}:${minute}）`;
                }
            } else if (diffTime < 24 * 3600 * 1000 && diffTime > 5 * 3600 * 1000) {
                // 今天5个小时 - 24小时：显示今天 时分
                const dateTime = new Date(time);
                let h = dateTime.getHours();

                h = h < 10 ? `0${h}` : h;
                let minute = dateTime.getMinutes();

                minute = minute < 10 ? `0${minute}` : minute;

                const padding = new Date().getDay() - soureDate.getDay();

                if (padding > 0) {
                    return `昨天（${h}:${minute}）`;
                } else {
                    return `今天（${h}:${minute}）`;
                }
            } else if (diffTime < 5 * 3600 * 1000 && diffTime > 3600 * 1000) {
                // 5个小时内1小时之前：显示几个小时前
                const dayNum = Math.floor(diffTime / (60 * 60 * 1000));

                return `${dayNum}小时前`;
            } else if (diffTime < 3600 * 1000 && diffTime > 5 * 60 * 1000) {
                // 1小时内5分钟之前：显示几分钟前
                const minuteNum = Math.floor(diffTime / (60 * 1000));

                return `${minuteNum}分钟前`;
            } else if (diffTime < 5 * 60 * 1000 && diffTime > 0) {
                // 5分钟以内：显示刚刚
                return "刚刚";
            }
        } else {
            return "";
        }
        return "";
    },

    /**
     * 返回值有两种！

     1、负值 代表过去了多少天

     2、正值 代表距离设定的时间还有多少天
     * @param yourDate
     * @returns {number}
     */
    getBeforeDate(yourDate) {
        const now = new Date();
        const aftertime = new Date(yourDate);
        let year = now.getFullYear();
        let mon = now.getMonth() + 1;
        let day = now.getDate();
        let year_after = aftertime.getFullYear();
        let mon_after = aftertime.getMonth() + 1;
        let day_after = aftertime.getDate();
        let chs = 0;

        // 获取当月的天数
        function DayNumOfMonth(Year, Month) {
            return 32 - new Date(Year, Month - 1, 32).getDate();
        }

        if (aftertime.getTime() - now.getTime() < 0) {
            const temp1 = day_after;
            const temp2 = mon_after;
            const temp3 = year_after;

            day_after = day;
            mon_after = mon;
            year_after = year;
            day = temp1;
            mon = temp2;
            year = temp3;
        }
        if (year == year_after) {
            // 不跨年
            if (mon == mon_after) {
                // 不跨年不跨月
                chs += day_after - day;
            } else {
                // 不跨年跨月
                chs += DayNumOfMonth(year, mon) - day + 1; // 加上第一个不满的
                for (let i = 1; i < mon_after - mon; i++) {
                    chs += DayNumOfMonth(year, mon + i);
                }
                chs += day_after - 1; // 加上
            }
        } else {
            // 存在跨年
            chs += DayNumOfMonth(year, mon) - day + 1; // 加上开始年份不满的一个月
            for (let m = 1; m < 12 - mon; m++) {
                chs += DayNumOfMonth(year, mon + m);
            }
            for (let j = 1; j < year_after - year; j++) {
                if ((year + j) % 400 == 0 || ((year + j) % 4 == 0 && (year + j) % 100 != 0)) {
                    chs += 366;
                } else {
                    chs += 365;
                }
            }
            for (let n = 1; n <= mon_after; n++) {
                chs += DayNumOfMonth(year_after, n);
            }
            chs += day_after - 1;
        }
        if (aftertime.getTime() - now.getTime() < 0) {
            return -chs;
        } else {
            return chs;
        }
    },

    /**
     * 秒 转 分+秒
     */
    missToMs(s) {
        if (!s) {
            return "0分钟";
        }
        const h = Math.floor(s / 60);
        // 计算秒
        // 算法：取得秒%60的余数，既得到秒数
        s %= 60;
        let showTime = "";

        if (h > 0) {
            showTime = `${h}分钟`;
        }
        if (s > 0) {
            showTime = `${showTime + s}秒`;
        }
        return showTime;
    },

    /**
     * 秒 -> 时分秒
     */
    secondToHms(second) {
        if (!second || second == 0) {
            return "00:00:00";
        }
        let hh = Math.floor(second / 3600);
        let mm = Math.floor((second / 60) % 60);
        let ss = Math.floor(second % 60);

        hh = hh < 10 ? "0" + hh : hh;
        mm = mm < 10 ? "0" + mm : mm;
        ss = ss < 10 ? "0" + ss : ss;
        return `${hh}:${mm}:${ss}`;
    },

    minuteToMs(m) {
        let s;

        s = m * 60;
        // 计算分钟
        // 算法：将秒数除以60，然后下舍入，既得到分钟数
        let h;

        h = Math.floor(s / 60);
        // 计算秒
        // 算法：取得秒%60的余数，既得到秒数
        s = Math.round(s % 60);
        // 将变量转换为字符串
        h += "";
        s += "";
        return `${h}分${s}秒`;
    },
    minuteToMinSecond(m) {
        let s;

        s = m * 60;
        // 计算分钟
        // 算法：将秒数除以60，然后下舍入，既得到分钟数
        let h;

        h = Math.floor(s / 60);
        // 计算秒
        // 算法：取得秒%60的余数，既得到秒数
        let second = Math.round(s % 60);
        // 将变量转换为字符串
        h += "";
        s = second + "";
        if (second > 0) {
            return `${h}分钟${s}秒`;
        } else {
            return `${h}分钟`;
        }
    },
    /**
     * 日期字符串 --> 日期没有时分秒  e.g 2018-10-08 00:00:00  --> 2018-10-08
     */
    formatterDateYMDStr(str) {
        if (str == null || str.length == 0) {
            return "";
        }
        if (str.length < 10) {
            return str;
        }
        const result = str.substr(0, 10);

        return result;
    },

    /**
     * 秒-->分
     */
    formatterSecondToMinite(second) {
        if (second == null || second == 0) {
            return 0;
        }
        const min = second / 60;
        const m = Math.floor(min);

        return m;
    },
    /*
     * 日期转化为 年月 格式
     * */
    formatterYearMonthStr(dateString) {
        const date1 = dateString && dateString.length > 0 ? new Date(dateString) : new Date();
        const year = date1.getFullYear();
        const month = date1.getMonth() + 1;

        return `${year}年${month}月`;
    },
    /*
     * 日期转化为 年月日 格式
     * */
    formatterYearMonthDayStr(dateString) {
        const date1 = new Date(dateString);
        const year = date1.getFullYear();
        const month = date1.getMonth() + 1;
        const day = date1.getDate();

        return `${year}年${month}月${day}日`;
    },
    /**
     * 比较两个日期的大小
     * @param d1
     * @param d2
     * @return boolean d1 < d2；false:d1>=d2
     */
    compareDate(d1, d2) {
        const date1 = new Date(Date.parse(d1));
        const date2 = new Date(Date.parse(d2));
        if (date1 < date2) {
            return true;
        }
        return false;
    },
    /**
     * 比较两个日期的大小
     * @param d1
     * @param d2
     * @return boolean d1 < d2；false:d1>=d2
     */
    compareDateEques(d1, d2) {
        const date1 = new Date(Date.parse(d1));
        const date2 = new Date(Date.parse(d2));
        if (date1 <= date2) {
            return true;
        }
        return false;
    },
    /**
     * 获取下个月的最后一天
     */
    nextMonthLastDay() {
        const time = new Date();
        let year = time.getFullYear();
        let month = time.getMonth() + 2;
        if (month > 12) {
            // 跨年处理
            month = 1;
            year = year + 1;
        }
        const day = this.nextMonthDay(year, month);
        if (month < 10) {
            month = `0${month}`;
        }
        const result = `${year}-${month}-${day}`;
        // console.log("nextMonthLastDay result",result);
        return result;
    },
    /**
     * 获取对应年月的天数
     * @param year
     * @param month
     */
    nextMonthDay(year, month) {
        const day31 = [1, 3, 5, 7, 8, 10, 12];
        const day30 = [4, 6, 9, 11];

        if (day31.indexOf(month) > -1) {
            return 31;
        } else if (day30.indexOf(month) > -1) {
            return 30;
        } else {
            if (this.isLeapYear(year)) {
                return 29;
            } else {
                return 28;
            }
        }
    },
    /**
     * 是否为闰年
     */
    isLeapYear(year) {
        return year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);
    },
    /**
     * 获取指定日期的前一天
     */
    getPreDay() {
        // if(dateStr){
        return this.dateFtt("yyyy-MM-dd", new Date(Date.now() - 8.64e7));
        // }
        // return "";
    },
};

/**
 *  秒转化为天 lzb 2023-09-11
 */
export const secondsToDays = (seconds) => {
    const secondsInADay = 24 * 60 * 60; // 一天有多少秒
    const days = seconds / secondsInADay;
    return days;
};
/**
 *  获取日期类型 lzb P1.0.28 20231214
 * @param {*} dateString
 * @returns
 */
export const getDateType = (dateString) => {
    if (!dateString || dateString.length == 0) {
        return "date";
    }
    const date = new Date(dateString);
    if (date.getMonth() !== 0 && date.getDate() !== 1) {
        return "date"; // 既有月份又有日期
    } else if (date.getMonth() !== 0) {
        return "month"; // 只有年份和月份
    } else {
        return "year"; // 只有年份
    }
};
/**
 * 获取日期类型格式 lzb P1.0.28 20231214
 * @param {*} dateString
 * @returns
 */
export const getDatePickerTypeFormatter = (dateString) => {
    const type = getDateType(dateString);
    if (type == "date") return "yyyy-MM-dd";
    else if (type == "month") return "yyyy-MM";
    else if (type == "year") return "yyyy";
    else return "yyyy-MM-dd";
};
