import { defaultBillingInfoListTableColumns } from "../../utils/constants/TableColumnsDefault";

/**
 * table动态控制展示的列
 *
 */
const tableColumns = {
    namespaced: true,
    state: {
        pageListObj: {}, // 页面列表对象
        billingInfoList: defaultBillingInfoListTableColumns, // 组织计费信息
    },
    mutations: {
        SET_BILLING_INFO_LIST: (state, billingInfoList) => {
            state.billingInfoList = billingInfoList;
        },
        SET_PAGE_LIST_OBJ: (state, obj) => {
            state.pageListObj[obj.key] = obj.value || [];
        },
        REMOVE_PAGE_LIST_OBJ: (state) => {
            state.pageListObj = {};
        },
    },
    actions: {
        setBillingInfoList: ({ commit }, billingInfoList) => {
            commit("SET_BILLING_INFO_LIST", billingInfoList);
        },

        getBillingInfoList: ({ state }) => {
            return state.billingInfoList;
        },
        setPageListObj: ({ commit }, obj) => {
            commit("SET_PAGE_LIST_OBJ", obj);
        },

        getPageListObj: ({ state }, key) => {
            return state.pageListObj[key];
        },
        removePageListObj: ({ commit }) => {
            commit("REMOVE_PAGE_LIST_OBJ");
        },
        /**
         * 清除所有数据
         */
        removeAllTableColumns() {},
    },
};
export default tableColumns;
