import { Constants } from "../constants/Constants";
import FuncHelper from "@utils/helper/FuncHelper";
import NumberHelper from "@utils/helper/NumberHelper";
import ConstantsAPI from "@utils/constants/ConstantsAPI";

export default {
    /**
     * 公司简称
     */
    formatterOrgAbbreviation(orgName) {
        const that = this;
        let abbreviation = "";
        // 1.结尾的删除
        Constants.endWithOrgName.forEach((item, index) => {
            if (that.confirmEnding(orgName, item)) {
                abbreviation = orgName.substring(0, orgName.lastIndexOf(item));
                return abbreviation;
            }
        });
        if (abbreviation == "") {
            abbreviation = orgName;
        }
        // 2.替换的字样
        abbreviation = abbreviation.replace(/公司总公司/g, "总公司");
        // 3.省市区县自治县等字样删除
        abbreviation = abbreviation.replace(/省/g, "");
        abbreviation = abbreviation.replace(/市/g, "");
        abbreviation = abbreviation.replace(/区/g, "");
        abbreviation = abbreviation.replace(/县/g, "");
        abbreviation = abbreviation.replace(/自治/g, "");
        abbreviation = abbreviation.replace(/自治县/g, "");
        return abbreviation;
    },
    /**
     * 判断字符串是以指定字符串结尾
     * @param str 原始字符串
     * @param endStr 结尾字符串
     * @returns {boolean}
     */
    confirmEnding(str, endStr) {
        const d = str.length - endStr.length;
        return d >= 0 && str.lastIndexOf(endStr) == d;
    },
    /**
     * 处理适用地区
     */
    formatterUseAddress(item) {
        if (!item.extJsonObj || !item.extJsonObj.areaList || item.extJsonObj.areaList.length == 0) {
            return [];
        }
        const tempList = [];
        item.extJsonObj.areaList.map((object) => {
            const str = this.formatterAddressFullAppend(object, "-");
            tempList.push(str);
        });
        return tempList;
    },
    /**
     * 处理培训类别
     */
    formatterSubjectList(item) {
        if (!item.extJsonObj || !item.extJsonObj.subjectList || item.extJsonObj.subjectList.length == 0) {
            return [];
        }
        const tempList = [];
        item.extJsonObj.subjectList.map((object) => {
            const str = object.subjectName;
            tempList.push(str);
        });
        return tempList;
    },
    /**
     * 处理培训类别
     */
    formatterIndustryList(item) {
        if (!item.extJsonObj || !item.extJsonObj.industryList || item.extJsonObj.industryList.length == 0) {
            return [];
        }
        const tempList = [];
        item.extJsonObj.industryList.map((object) => {
            const str = object.industryName;
            tempList.push(str);
        });
        return tempList;
    },
    /**
     * 处理岗位list
     */
    formatterDutyList(item) {
        if (!item.extJsonObj || !item.extJsonObj.dutyList || item.extJsonObj.dutyList.length == 0) {
            return [];
        }
        const tempList = [];
        item.extJsonObj.dutyList.map((object) => {
            const str = object.dutyName;
            tempList.push(str);
        });
        return tempList;
    },
    /**
     * 地址省市区拼接
     */
    formatterAddressFullAppend(item, splitStr = "") {
        if (!item) return "-";

        if (item instanceof String) {
            return item;
        }
        let str = "";
        if (item.province && item.province.length > 0) {
            str = item.province;
        }
        if (item.city && item.city.length > 0) {
            str = `${str}${splitStr}${item.city}`;
        }
        if (item.district && item.district.length > 0) {
            str = `${str}${splitStr}${item.district}`;
        }
        return str;
    },
    /**
     * @description: 学员分类  fbz
     * @param {*} filterUserClazz
     * @param {*} function
     * @return {*}
     */
    filterUserClazz(value) {
        let result = "-";
        Constants.clazzList.some((item) => {
            if (item.value === value) {
                result = item.label;
                return true;
            }
        });
        return result;
    },
    /**
     * 格式化分转元
     */
    formatterMoneyPointStr(money) {
        if (money === null || money === undefined) {
            return "-";
        }
        const result = NumberHelper.regFenToYuan(money);
        // result = NumberHelper.toFixed(result, 2);
        return String(result);
    },
    /**
     * 金额有小数点的处理 分-->元 可以负数
     */
    formatterMoneyOptionsMinusPointStr(money) {
        if (money === null || money === undefined) {
            return "0.00";
        }
        const result = FuncHelper.regFenToYuan(money);
        // result = FuncHelper.toFixed(result,2);
        return String(result);
    },

    /**
     * 数据设备来源
     * @param val
     * @returns {string}
     */
    formatterSourceType(val) {
        let valInt = val;

        if (val instanceof Object) {
            valInt = val.sourceType;
        }

        const typeStr = "";
        if (valInt == 10) {
            return "电脑(PC)";
        } else if (valInt == 14) {
            return "现场考试";
        } else if (valInt == 15) {
            return "现场学习";
        } else if (valInt == 20) {
            return "安卓(APP)";
        } else if (valInt == 21) {
            return "苹果(APP)";
        } else if (valInt == 22) {
            return "公众号";
        } else if (valInt == 23) {
            return "小程序";
        } else if (valInt == 30) {
            return "电脑(h5)";
        } else if (valInt == 31) {
            return "安卓（h5）";
        } else if (valInt == 32) {
            return "苹果（h5）";
        } else {
            return "--";
        }
    },

    /**
     *  计算分钟秒数量
     */
    missToMinuteStr(s) {
        // 计算分钟
        // 算法：将秒数除以60，然后下舍入，既得到分钟数
        if (s == undefined || s == null) {
            return "0秒";
        }
        if (s == 0) {
            return "0秒";
        }
        let h;
        h = Math.floor(s / 60);
        // 计算秒
        // 算法：取得秒%60的余数，既得到秒数
        s %= 60;
        let showTime = "";
        if (h > 0) {
            if (s > 0) {
                showTime = `${h}分${s}秒`;
            } else {
                showTime = `${h}分钟`;
            }
        } else {
            showTime = `${s}秒`;
        }

        return showTime;
    },
    /**
     * 资源类型
     */
    formatterResourcesType(val) {
        switch (val) {
            case null:
                return "所有";
            case 11:
                return "菜单";
            case 12:
                return "按钮";
            case 13:
                return "表单";
            case 14:
                return "资源块";
            case 15:
                return "标签";
            case 16:
                return "数据选项";
        }
    },

    /**
     * 过滤url
     */
    filterSourceFullURL(url) {
        if (url && (url.indexOf("http:") !== -1 || url.indexOf("https:") !== -1)) {
            return url;
        } else {
            return ConstantsAPI.getAliOssDomain() + url;
        }
    },
    /**
     * 转换空字符
     * @param {*} value
     * @param {*} formatterValue
     */
    filterNullString(value, formatterValue) {
        const getType = Object.prototype.toString.call(value);
        if (getType !== "[object Number]" && !value) {
            return formatterValue;
        }
        return value;
    },
    /**
     * 转换状态
     * @param {*} val
     * @param {*} obj
     * @returns
     */
    filterState(val, obj) {
        if (obj && Object.keys.length > 0) {
            return obj[val];
        }
        return "";
    },

    /**
     * 过滤字符串
     */
    formatterStr(val) {
        if (!val || val == null || val == undefined || val.length == 0) {
            return this.formatterNoDefaultStr();
        } else {
            return val;
        }
    },
    /**
     *  显示默认字符
     */
    formatterNoDefaultStr() {
        return "-";
    },
    /**
     * 获取树形的node布局 lzb -2021-11-15
     */
    getNodeTitleStyle(level) {
        const paddingLeft = level <= 2 ? 0 : 10 * (level - 2);
        const color = getColorLevel(level);
        return {
            "padding-left": `${paddingLeft}px`,
            color: `${color}`,
        };

        function getColorLevel(inLevel) {
            switch (inLevel) {
                case 1:
                    return "#333333";
                case 2:
                    return "#666666";
                case 3:
                    return "#999999";
                default:
                    return "#999999";
            }
        }
    },
    /**
     * 获取全部节点的数据
     */
    getNodeAllItem(key = "title") {
        const obj = {
            children: null,
            id: null,
            parentId: null,
            title: "全部",
            level: 1,
            isShow: 1,
        };
        key = key || "title";
        obj[key] = "全部";
        return obj;
    },
    /**
     * 增加树形的级别
     * @param list
     * @param level
     * @returns {*}
     */
    addTreeListLevel(list, level = 1, maxLevel = 4) {
        if (!list || list.length == 0) return list;
        list.forEach((item) => {
            item.level = level;
            item.disabled = level > maxLevel;
            if (item.children && item.children.length > 0) {
                this.addTreeListLevel(item.children, level + 1, maxLevel);
            }
        });
        return list;
    },
    /**
     * 性别
     * @return {string}
     */
    formatGender(gender) {
        if (gender === 2) {
            return "男";
        } else if (gender === 3) {
            return "女";
        }
        return "未知";
    },
    /**
     * 身份证脱敏
     * @param carNo
     */
    carNoCover(carNo) {
        if (carNo) {
            return `${carNo.substr(0, 8)}******${carNo.substr(carNo.length - 4)}`;
        }
        return "-";
    },
    /**
     * 手机号码脱敏
     * @param carNo
     */
    phoneCover(phone) {
        if (phone) {
            return `${phone.substr(0, 3)}****${phone.substr(phone.length - 4)}`;
        }
        return "-";
    },
    /**
     * 计划培训状态
     * @return {string}
     */
    formatterBaseInfoState(val) {
        if (val === 0) {
            return "暂停";
        } else if (val === 11) {
            return "无效";
        } else if (val === 12) {
            return "草稿";
        } else if (val === 13) {
            return "审核驳回";
        } else if (val === 14) {
            return "审核通过";
        } else if (val === 20) {
            return "未开始";
        } else if (val === 21) {
            return "进行中";
        } else if (val === 22) {
            return "补学中";
        } else if (val === 25) {
            return "完成";
        } else if (val === 30) {
            return "过期未完成";
        } else {
            return "-";
        }
    },
    /**
     *  培训状态
     */
    formatterTrainingState(trainingState) {
        switch (trainingState) {
            case 13:
                return "未开始";
            case 14:
                return "未培训";
            case 16:
                return "培训中";
            case 17:
                return "学习结束，学时待审核";
            case 18:
                return "学时审核不通过";
            case 21:
                return "考试待审核";
            case 22:
                return "考试审核不通过";
            case 23:
                return "待考试";
            case 24:
                return "考试未通过(补考中)";
            case 25:
                return "考试通过成绩待审核";
            case 26:
                return "成绩审核不通过";
            case 30:
                return "培训结束(成绩审核通过)";
            default: {
                return "-";
            }
        }
    },
    /**
     * @description: 地址拼接2022-11-25
     * @param {*} item
     * @return {*}
     */
    formatterAddressAppendStyle(item) {
        if (!item) return "-";

        if (item instanceof String) {
            return item;
        }
        let str = "";
        if (item.province && item.province.length > 0) {
            str = item.province;
        }
        if (item.city && item.city.length > 0) {
            str = `${str}-${item.city}`;
        }
        if (item.district && item.district.length > 0) {
            str = `${str}-${item.district}`;
        }
        return str;
    },
    /**
     * @description: 地址拼接2024-03-11 lzb
     * @param {*} item
     * @return {*}
     */
    formatterAddressUnitStr(item) {
        if (!item) return "-";

        if (item instanceof String) {
            return item;
        }
        let str = "";
        if (item.province && item.province.length > 0) {
            str = item.province;
        }
        if (item.city && item.city.length > 0) {
            str = `${str}-${item.city}`;
        }
        if (item.district && item.district.length > 0) {
            str = `${str}-${item.district}`;
        }
        return str && str.length > 0 ? str : "-";
    },
};
