// htmlToPdf.js
// 导出页面为PDF格式
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
import printJS from "print-js";
import "print-js/dist/print.css";
import { HudGlobal } from "./HudGlobal";

export default {
    install(Vue, options) {
        // 导出pdf
        Vue.prototype.getPdf = function (dom, title) {
            HudGlobal.showLoadingFullScreen("正在导出...");
            html2Canvas(document.querySelector(dom), {
                allowTaint: true,
                taintTest: false,
                useCORS: true,
                y: 0, // 对Y轴进行裁切
                // width:1200,
                // height:5000,
                dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
                scale: 4, //按比例增加分辨率
            })
                .then(function (canvas) {
                    let contentWidth = canvas.width;
                    let contentHeight = canvas.height;
                    let pageHeight = (contentWidth / 592.28) * 841.89;
                    let leftHeight = contentHeight;
                    let position = 0;
                    let imgWidth = 595.28;
                    let imgHeight = (592.28 / contentWidth) * contentHeight;
                    let pageData = canvas.toDataURL("image/jpeg", 1.0);
                    let PDF = new JsPDF("", "pt", "a4");
                    if (leftHeight < pageHeight) {
                        PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
                    } else {
                        while (leftHeight > 0) {
                            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
                            leftHeight -= pageHeight;
                            position -= 841.89;
                            if (leftHeight > 0) {
                                PDF.addPage();
                            }
                        }
                    }
                    HudGlobal.dismissLoadingFullScreen();
                    PDF.save(title + ".pdf");
                    console.log("pdf生成成功:", title + ".pdf");
                })
                .catch((e) => {
                    console.log("====导出pdf失败=====", e);
                    HudGlobal.dismissLoadingFullScreen();
                    HudGlobal.showWarningWithMessage("导出pdf失败");
                });
        };
        // 打印
        Vue.prototype.pintDom = function (dom, title) {
            // 转图片打印
            HudGlobal.showLoadingFullScreen("正在打印...");
            html2Canvas(document.querySelector(dom), {
                allowTaint: false,
                useCORS: false,
                height: document.querySelector(dom).outerHeight,
                width: document.querySelector(dom).outerWidth,
                windowWidth: document.body.scrollWidth,
                windowHeight: document.body.scrollHeight,
            }).then((canvas) => {
                const url = canvas.toDataURL();
                printJS({
                    printable: url,
                    type: "image",
                    documentTitle: title,
                });
                HudGlobal.dismissLoadingFullScreen();
            });
        };
    },
};
